<template>
  <div>
    <part-title title="上传固件"></part-title>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
      <el-form-item label="选择应用" prop="deviceType">
        <el-select v-model="ruleForm.deviceType" placeholder="请选择" size="small">
          <el-option label="HFM" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="版本号" prop="version">
        <el-input-number class="input-number" v-model="ruleForm.versionList[0]" size="small" :min="0"  controls-position="right"></el-input-number> .
        <el-input-number class="input-number" v-model="ruleForm.versionList[1]" size="small" :min="0" controls-position="right"></el-input-number> .
        <el-input-number class="input-number" v-model="ruleForm.versionList[2]" size="small" :min="0" controls-position="right"></el-input-number> .
        <el-input-number class="input-number" v-model="ruleForm.versionList[3]" size="small" :min="0" controls-position="right"></el-input-number>
      </el-form-item>
      <el-form-item label="更新内容" prop="description">
        <el-input type="textarea" v-model="ruleForm.description" placeholder="请输入更新内容" :autosize="{ minRows: 10, maxRows: 10}" maxlength="200" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="上传升级包" prop="path">
        <el-upload
          class="upload-demo"
          action=""
          :http-request="uploadReq"
          multiple
          :limit="1"
          accept=".bin"
          :on-remove="handleRemove"
          :file-list="fileList">
          <el-button :disabled="disabledUpload" size="small" type="primary" :slot="disabledUpload ? 'tip' :'trigger'">点击上传</el-button>
          <div v-if="!disabledUpload" slot="tip" class="el-upload__tip">只能上传不超过1M</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="是否强制升级" prop="force">
        <el-radio-group v-model="ruleForm.force" disabled>
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button @click="cancel()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { addFeedBack } from '@/serve/module/firmwareUpdateManage'
import { ossInit } from '@/utils/ali-oss-sdk-firmware'
import PartTitle from '@/components/Part'
export default {
  components: {
    PartTitle
  },
  data () {
    const validateVersion = (rule, value, callback) => {
      console.log('1111:', this.ruleForm.versionList[0])
      if (this.ruleForm.versionList[0] === undefined || this.ruleForm.versionList[1] === undefined || this.ruleForm.versionList[2] === undefined || this.ruleForm.versionList[3] === undefined) {
        callback(new Error('请填写版本号'))
        return false
      } else if (this.ruleForm.versionList[0] > 255 ||
        this.ruleForm.versionList[1] > 255 ||
        this.ruleForm.versionList[2] > 255 ||
        this.ruleForm.versionList[3] > 255) {
        callback(new Error('每位版本号的值不能大于255'))
        return false
      } else {
        callback()
        return false
      }
    }
    return {
      ruleForm: {
        deviceType: null,
        force: 0,
        description: '',
        path: '',
        version: '',
        versionList: []
      },
      fileList: [],
      rules: {
        deviceType: [
          { required: true, message: '请选择应用', trigger: 'blur' }
        ],
        version: [
          { validator: validateVersion, trigger: 'blur' }
        ],
        path: [
          { required: true, message: '请上传升级包', trigger: 'change' }
        ],
        description: [
          { required: true, message: '请填写更新内容', trigger: 'blur' }
        ]
      },
      deviceType: 'hmf',
      uploadInfo: {},
      disabledUpload: false
    }
  },
  created () {
    ossInit()
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.version = this.ruleForm.versionList[0] + '.' + this.ruleForm.versionList[1] + '.' + this.ruleForm.versionList[2] + '.' + this.ruleForm.versionList[3]
          addFeedBack(this.ruleForm).then(res => {
            if (res.code === 200) {
              this.$router.push({
                name: 'firmwareManage'
              })
            } else {
              this.$message.error(res.message)
            }
          }).catch
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    cancel () {
      this.$router.push({
        name: 'firmwareManage'
      })
    },
    async uploadReq (file) {
      const controlFileName = file.file.name.substring(0, file.file.name.lastIndexOf('.'))
      const filename = controlFileName + '-' + new Date().getTime() + '.bin'
      this.ruleForm.path = `device/firmware/${this.deviceType}/${filename}`
      const OSS = this.$store.getters['Oss/getOss']
      this.result = await OSS.put(`device/firmware/${this.deviceType}/${filename}`, file.file)
      this.fileList.push({
        name: filename,
        url: this.ruleForm.path
      })
      this.$refs.ruleForm.validateField('path')
      this.disabledUpload = true
    },
    handleRemove () {
      this.ruleForm.path = ''
      this.disabledUpload = false
      this.fileList = []
    }
  }
}
</script>
<style scoped lang="scss">
.demo-ruleForm {
  margin-top: 20px;
  width: 560px;
  .input-number {
    width: 100px;
  }
}
::v-deep .el-form-item__label::before {
    content: '*';
    color: #F56C6C;
    margin-right: 4px;
}
</style>
