<template>
  <div>
    <el-drawer
    title="录入回执单信息"
    :visible="visible"
    direction="rtl"
    :wrapperClosable="false"
    :close-on-press-escape="false"
    @close="handleClose"
    :size="drawerSize">
    <el-menu default-active="1" class="el-menu-demo" mode="horizontal" @select="handleSelect" text-color="#51565D" active-text-color="#409EFF" background-color="#fff">
      <el-menu-item index="1">ICM</el-menu-item>
      <el-menu-item index="2">ICD</el-menu-item>
    </el-menu>
    <div v-if="tabNum === '1'">
      <el-form style="width:339px" label-position="left" ref="formAddReceipt" :rules="rules"  :model="formData" label-width="100px" size="small" class="formAddReceipt">
        <!-- <el-form-item label="性别" prop="patientGender">
          <el-select
            v-model="formData.patientGender"
            style="width: 100%;"
            placeholder="请选择">
            <el-option
              v-for="item in genderList"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="品牌型号">
          <div style="width: 100%;">
            <el-select
              v-model="formData.modelCode"
              style="width: 160px;float: left;"
              placeholder="请选择"
              @focus="onSelectmodelCode">
              <el-option
                v-for="item in brandModelOptions"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
            <el-button style="float: right;" @click="onAddModelInfo">
              <i class="el-icon-plus"></i>
              添加</el-button>
          </div>
        </el-form-item>
        <el-form-item label="设备序列号" prop="deviceSn">
          <el-popover
            placement="right"
            title="检测到用户提交"
            width="230"
            trigger="manual"
            v-model="visiblePopover">
            <div v-if="searchDeviceSnOptions[0]">
              <p>{{'序列号：' + searchDeviceSnOptions[0].deviceSn}}</p>
              <p>{{'患者姓名：' + searchDeviceSnOptions[0].patientName}}</p>
              <p>{{'身份证号：'+ searchDeviceSnOptions[0].patientIdNumber}}</p>
            </div>
            <el-input v-model="formData.deviceSn" placeholder="请输入设备序列号" slot="reference">
              <el-button slot="append" icon="el-icon-search" @click="onSearchDeviceSn"></el-button>
            </el-input>
          </el-popover>
        </el-form-item>
        <el-form-item label="患者姓名" prop="patientName">
          <el-autocomplete placeholder="请输入患者姓名" v-model="formData.patientName" :fetch-suggestions="querySearchName"  @select="onSearchName" style="width:100%" :popper-append-to-body="false"></el-autocomplete>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input v-model="formData.patientIdNumber1" placeholder="请输入身份证号" @input="onChangeIdNumber" maxlength="19">
          </el-input>
        </el-form-item>
        <el-form-item label="联系手机号">
          <el-input
            v-model="formData.patientPhone"
            placeholder="请填写联系手机号"
            maxlength="11"
            @change="onChangePatientPhone"
          ></el-input>
        </el-form-item>
        <el-form-item label="植入医院" prop="hospitalId">
          <div style="width: 100%;">
            <el-select
            v-model="formData.hospitalId"
            style="width: 160px;float: left;"
            placeholder="请选择"
            @focus="onSelectHospital">
            <el-option
              v-for="item in hospitalOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
            <el-button style="float: right;" @click="onAddHospital">
              <i class="el-icon-plus"></i>
              添加</el-button>
          </div>
        </el-form-item>
        <el-form-item label="植入日期" prop="implantDate">
          <el-date-picker
            v-model="formData.implantDate"
            type="date"
            placeholder="请选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="timestamp"
            :picker-options="pickerOptions"
            style="width:100%">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="医院术者">
          <el-input v-model="formData.operator" placeholder="请填写医院术者" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="患者病因">
          <el-input v-model="formData.etiology" type="textarea" rows="1" maxlength="20" placeholder="请填写患者病因"></el-input>
        </el-form-item>
        <el-form-item label="患者住址">
          <el-input v-model="formData.addressDetail" type="textarea" rows="1" maxlength="50" placeholder="请填写患者住址"></el-input>
        </el-form-item>
        <p style="color:#606266;margin-bottom: 12px;"><span style="margin-right:4px;color:#F56C6C;">*</span>上传回执单图片</p>
        <el-upload
          ref="pictureUpload"
          :class="uploadDisabled"
          action=""
          list-type="picture-card"
          :file-list="fileList"
          accept=".jpg,.JPG,.png,.PNG,.jpeg,.JPEG"
          :limit="1"
          :http-request="uploadImgReq">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
              <img
                class="el-upload-list__item-thumbnail"
                :src="handelePictureThumbnail(file)" alt=""
              >
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
          <p v-if="isShowUrlTips" style="color:#F56C6C;font-size: 12px;">请上传回执单图片</p>
      </el-form>
      <div class="drawer-footer">
        <div class="footer-box">
          <el-button class="footer-btn" @click="handleClose" size="small">返回</el-button>
          <el-button class="footer-submit-btn" type="primary" size="small" @click="onSubmit">提交</el-button>
        </div>
      </div>
    </div>
    </el-drawer>
    <ConfirmReceiptInfo :visible.sync="isShowConfirmReceiptInfo" :receiptInfo="receiptInfo" @enterReceiptSuccess="enterReceiptSuccess" >
    </ConfirmReceiptInfo >
    <AddHospital :visible.sync="isAddHospital" @addHospital="addHospital">
    </AddHospital>
    <AddModelInfo :visible.sync="isAddModelInfo" @addModelInfo="addModelInfo">
    </AddModelInfo>
    <el-dialog :visible.sync="dialogVisible" style="z-index: 999999;">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
  </div>
</template>
<script>
import options from '@/utils/region'
import { getDeviceBrandModelList, getHospitalList, getPendDeviceInfo } from '@/serve/module/common'
import { searchPatientSimpleInfo } from '@/serve/module/receipt'
import { ossInit } from '@/utils/ali-oss-sdk'
import ConfirmReceiptInfo from './confirmReceiptInfo.vue'
import AddHospital from './addHospital.vue'
import AddModelInfo from './addModelInfo.vue'
// import { constants } from 'buffer'
export default {
  components: {
    ConfirmReceiptInfo,
    AddHospital,
    AddModelInfo
  },
  data () {
    const checkpatientIdNumber = (rule, value, callback) => {
      const reg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$)/
      if (this.changeIdNumber && this.formData.patientIdNumber1 && reg.test(this.formData.patientIdNumber1) === false) {
        callback(new Error('格式有误，请输入正确的身份证号码'))
        return false
      } else if (this.changeIdNumber && value && reg.test(value) === false) {
        callback(new Error('格式有误，请输入正确的身份证号码'))
        return false
      } else {
        callback()
        return true
      }
    }
    const checkpatientPhone = (rule, value, callback) => {
      const reg = /^1[0-9]{10}$/
      if (this.changePatientPhone && value && reg.test(value) === false) {
        callback(new Error('格式有误，请输入正确的联系手机号'))
        return false
      } else {
        callback()
        return true
      }
    }
    return {
      pickerOptions: {
        // 选择今天以及今天以前的日期
        disabledDate (time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },
      addressOptions: options, // 居住地地址列表
      hospitalOptions: [],
      genderList: [ // 性别列表
        { id: 2, label: '女' },
        { id: 1, label: '男' }
      ],
      brandModelOptions: [ // ICM品牌型号列表
      ],
      searchDeviceSnOptions: {},
      patientInfoOptions: [],
      patientInfo2Options: {},
      drawer: false,
      formData: {},
      defaultAddress: '', // 常住地

      changeIdNumber: false,
      changePatientPhone: false,
      changePatientName: false,

      isShowUrlTips: false,

      rules: {
        patientName: [
          { required: true, message: '请输入患者姓名' },
          { min: 2, max: 20, message: '格式有误，长度在 2-20 个字符', trigger: 'blur' },
          { pattern: /^[\u4E00-\u9FA5A-Za-z0-9·]+$/, message: '格式有误，请输入中文/英文/·' }
        ],
        gender: [
          { required: true, message: '请选择性别' }
        ],
        patientPhone: [
          { required: true, message: '请输入联系手机号' },
          { validator: checkpatientPhone, trigger: 'blur' }
        ],
        modelCode: [
          { required: true, message: '请选择品牌型号' }
        ],
        deviceSn: [
          { required: true, message: '请输入设备序列号', trigger: 'blur' },
          { pattern: /^[A-Za-z\d]+$/, message: '格式有误，请输入英文/数字' }
        ],
        patientIdNumber1: [
          { required: true, message: '请输入身份证号' },
          { validator: checkpatientIdNumber, trigger: 'blur' }
        ],
        hospitalId: [
          { required: true, message: '请选择植入医院' }
        ],
        implantDate: [
          { required: true, message: '请选择植入日期' }
        ],
        operator: [
          { required: true, message: '请填写医院术者' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' },
          { pattern: /^[\u4E00-\u9FA5A-Za-z·]+$/, message: '请输入中文/英文/·' }
        ],
        etiology: [
          { required: true, message: '请填写患者病因' },
          { max: 20, message: '最长为 40 个字符', trigger: 'blur' },
          { pattern: /^[\u4E00-\u9FA5]+$/, message: '请输入中文' }
        ],
        addressDetail: [
          { required: true, message: '请选择患者住址' },
          { max: 50, message: '最长为 100 个字符', trigger: 'blur' }
        ]
      },

      dialogImageUrl: '',
      dialogVisible: false,
      uploadDisabled: '',
      uploadImgList: [],

      isShowConfirmReceiptInfo: false,
      isAddHospital: false,
      isAddModelInfo: false,

      receiptInfo: {},

      tabNum: '1',
      visiblePopover: false,
      drawerSize: '400px',

      timeout: false,

      isFillIdNum: false,
      fileList: []
    }
  },
  props: {
    visible: {
      default: false,
      type: Boolean
    }
  },
  watch: {
    visible (val) {
      if (val) {
        ossInit()
        this.drawerSize = '400px'
        this.getDeviceBrandModelList()
        this.getHospitalList()
        this.isShowUrlTips = false
        this.formData = {}
        this.fileList = []
        this.uploadDisabled = ''
      }
    }
  },
  methods: {
    getDeviceBrandModelList () {
      getDeviceBrandModelList({ type: 1 }).then(res => {
        if (res) {
          this.brandModelOptions = res.data
        }
      })
    },
    getHospitalList () {
      getHospitalList().then(res => {
        if (res) {
          this.hospitalOptions = res.data
        }
      })
    },
    onSubmit () {
      this.$refs.formAddReceipt.validate((valid) => {
        if (!this.formData.imagePath) {
          this.isShowUrlTips = true
        }
        if (valid) {
          if (!this.formData.imagePath) {
            this.isShowUrlTips = true
          } else {
            this.isShowConfirmReceiptInfo = true
            this.formData.brandCode = '1001'
            this.formData.brandName = '无双医疗'

            this.brandModelOptions.forEach(item => {
              if (item.code === this.formData.modelCode) {
                this.formData.modelName = item.name
              }
            })
            this.receiptInfo = this.formData
            this.receiptInfo.patientIdNumber = this.formData.patientIdNumber1

            // if(this.changeIdNumber === false) {
            //   this.receiptInfo.pendingDeviceId = this.formData.pendingDeviceId
            //   this.$delete(this.receiptInfo,'patientIdNumber')
            // }
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleClose () {
      this.$emit('update:visible', false)
      this.visiblePopover = false
      this.$refs.formAddReceipt.resetFields()
      this.changeIdNumber = false
      this.changePatientPhone = false
      this.changePatientName = false
    },
    onChangeIdNumber (e) {
      console.log('****', e)
      this.changeIdNumber = true
      this.formData.patientIdNumber1 = e
      // this.$refs.formAddReceipt.validateField('patientIdNumber1')
      this.$forceUpdate()
    },
    onChangePatientPhone () {
      this.changePatientPhone = true
    },
    onChangePatientName () {
      this.changePatientName = true
    },
    handelePictureThumbnail (file) {
      this.formData.imagePathView = file.url
      return file.url
    },
    handleRemove (file) {
      file.url = ''
      this.$refs.pictureUpload.uploadFiles = []
      this.uploadDisabled = ''
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleSelect (e) {
      this.tabNum = e
    },
    async uploadImgReq (file) {
      const filename = new Date().getTime() + '-' + file.file.name
      const OSS = this.$store.getters['Oss/getOss']
      this.result = await OSS.put(`mp3/${filename}`, file.file)
      // 上传成功啦,设置上传样式
      this.isShowUrlTips = false
      console.log('result:', this.result.name)
      this.formData.imagePath = this.result.name
      this.uploadImgList.push({
        url: this.result.name
      })
      if (this.uploadImgList.length > 0) {
        this.uploadDisabled = 'disabled'
      }
    },
    onAddHospital () {
      this.isAddHospital = true
    },
    addHospital () {
      this.getHospitalList()
    },
    onAddModelInfo () {
      this.isAddModelInfo = true
    },
    addModelInfo () {
      this.getDeviceBrandModelList()
    },
    getPendDeviceInfo (params) {
      getPendDeviceInfo(params).then(res => {
        if (res.data.data) {
          this.searchDeviceSnOptions = res.data.data
          /// this.formData.patientIdNumber1 = res.data.data[0].patientIdNumber
          this.formData.pendingDeviceId = res.data.data[0].pendingDeviceId
          if (this.searchDeviceSnOptions.length > 0) {
            this.visiblePopover = true
            this.drawerSize = '630px'
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    searchPatientSimpleInfo (patientName) {
      searchPatientSimpleInfo(patientName).then(res => {
        if (res.data) {
          this.patientInfoOptions = []
          if (res.data.length > 0) {
            this.formData.patientIdNumber1 = res.data[0].patientIdNumber
            this.formData.pendingDeviceId = res.data[0].pendingDeviceId
            this.patientInfoOptions = [{
              value: res.data[0].patientName + '(' + res.data[0].patientIdNumber + ')'
            }]
            this.$forceUpdate()
          } else {
            this.patientInfoOptions = []
          }
        } else {
          this.patientInfoOptions = []
          // this.formData.patientIdNumber1 = null
          this.changeIdNumber = false
        }
      })
    },
    onSearchDeviceSn () {
      console.log('设备序列号数据：', this.formData.deviceSn)
      this.getPendDeviceInfo({
        deviceSn: this.formData.deviceSn
      })
    },
    async querySearchName (name, cb) {
      if (name) {
        this.formData.patientName = name
        await this.searchPatientSimpleInfo(name)
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          cb(this.patientInfoOptions)
        }, 1000 * Math.random())
      }
    },
    onSearchName (item) {
      this.formData.patientName = item.value.substring(0, item.value.lastIndexOf('('))
      this.formData.patientIdNumber = item.value.substring(item.value.lastIndexOf('(') + 1, item.value.lastIndexOf(')'))
      this.isFillIdNum = true
      this.patientInfoOptions = []
    },
    enterReceiptSuccess () {
      this.$emit('update:visible', false)
      this.$emit('enterReceiptSuccess1')
    },
    onSelectHospital () {
      this.getHospitalList()
    },
    onSelectmodelCode () {
      this.getDeviceBrandModelList()
    }
  }
}
</script>
<style scoped lang="scss">
.formAddReceipt {
  margin: 24px;
  .submit-btn {
    width: 100%;
  }
}
::v-deep .el-drawer__header {
  font-weight: 500;
  font-size: 16px;
  color: #0F1114;
  padding: 16px 0px 16px 24px;
  margin-bottom: 0;
  box-shadow: inset 0px -1px 0px #E7E8EA;
}

::v-deep .el-menu-item,.el-menu-item.is-active,.el-menu-item:hover {
  background: #fff !important;
  height: 40px;
  line-height: 50px;
}

.el-menu-demo {
  margin: 0px 16px;
}
.drawer-footer {
  width: 100%;
  height: 64px;
  box-shadow: inset 0px 1px 0px #E7E8EA;
  .footer-box {
    float: right;
    .footer-btn {
    margin: 16px 12px 16px 12px;
  }
    .footer-submit-btn {
      margin: 16px 24px 16px 0;
    }
  }
}

// ::v-deep .el-upload--picture-card {
//   width: 80px;
//   height: 80px;
// }
::v-deep .disabled .el-upload--picture-card{
    display: none;
}

::v-deep .el-dialog,.el-dialog__wrapper {
  z-index: 9999;
}

::v-deep .el-popper[x-placement^=bottom]{
		width: 300px !important;
    margin-left: 80px;
	}
</style>

<!-- <style lang="scss">
 ::v-deep .disabled .el-upload--picture-card{
    display: none;
  } -->
