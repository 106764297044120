<template>
  <div class="content">
    <header class="header">
    <!-- 顶部 -->
    <part-title title="蓝牙网关记录"></part-title>
  </header>
    <el-table
    :data="tableData">
      <el-table-column
        align="center"
        fixed
        label="序号"
        type="index"
        width="50">
      </el-table-column>
      <el-table-column
        align="center"
        prop="uploadTime"
        label="上传时间">
          <template slot-scope="scope">
            <span>{{moment.unix(scope.row.uploadTime / 1000).format('Y-MM-DD HH:mm:ss')}}</span>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="bluetoothAddress"
        label="蓝牙地址">
          <template slot-scope="scope">
            <span>{{scope.row.bluetoothAddress}}</span>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="data"
        label="数据"
        min-width="120">
        <template slot-scope="scope">
            <span>{{scope.row.data}}</span>
          </template>
      </el-table-column>
    </el-table>
    <div style="height: 80px"></div>
    <div class="paginationClass" v-if="tableData.length > 0">
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="searchData.pageNo"
      :page-size="searchData.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getBluetoothList } from '@/serve/module/common'
import { symptomOpts } from '@/utils/enum'
import PartTitle from '@/components/Part'
const moment = require('moment')
export default {
  components: {
    PartTitle
  },
  data () {
    return {
      moment: moment,
      symptomOpts,
      searchData: { // 搜索数据
        pageNo: 1, // 当前页
        pageSize: 20 // 每页条数
      },

      totalPage: 1,
      tableData: [],
      dataString: '',
      doctorInfo: {},
      circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      menuFlag: true

    }
  },
  created () {
    this.getList(this.searchData)
  },
  filters: {
    // 当标题字数超出时，超出部分显示’...‘。此处限制超出8位即触发隐藏效果
    ellipsis (value) {
      if (!value) return ''
      if (value.length > 8) {
        return value.slice(0, 8) + '...'
      }
      return value
    }
  },
  methods: {
    handleSizeChange (val) {
      this.searchData.pageSize = val
      this.getList(this.searchData)
    },
    handleCurrentChange (val) {
      this.searchData.pageNo = val
      this.getList(this.searchData)
    },
    getList (params) {
      getBluetoothList(params).then(res => {
        if (res) {
          this.tableData = res.data.data
          this.totalPage = res.data.total
        }
      })
    },
    handleData (row) {
      row.data.map(i => {
        this.dataString += i + '、'
        this.$set(row, 'dataString', this.dataString)
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-table th.el-table__cell>.cell {
  color: #909399;
  font-weight: 400;
  font-size: 14px;
 }
::v-deep .paginationClass {
  background: #fff;
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 210px;
  z-index: 100;
  padding: 24px 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
</style>
