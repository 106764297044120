<template>
  <div class="content">
    <header class="header">
      <part-title title="用户反馈"></part-title>
    </header>
    <el-table
    :data="tableData">
      <el-table-column
        align="center"
        fixed
        label="序号"
        type="index"
        width="50">
      </el-table-column>
      <el-table-column
        align="center"
        prop="channelName"
        label="渠道">
      </el-table-column>
      <el-table-column
        align="center"
        prop="deviceSn"
        label="用户">
          <template slot-scope="scope">
            <span>{{scope.row.creatorPhone}}</span>
          </template>
      </el-table-column>
      <el-table-column
        prop="brandType"
        label="反馈内容">
          <template slot-scope="scope">
           <pre v-if="scope.row.content" style="white-space: pre-wrap;word-wrap: break-word;">{{scope.row.content}}</pre>
           <div v-else>-</div>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="operator"
        label="联系方式">
        <template slot-scope="scope">
            <span v-if="scope.row.contactPhone">{{scope.row.contactPhone}}</span>
            <span v-else>-</span>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="brandModel"
        label="反馈时间">
        <template slot-scope="scope">
            <span v-if="scope.row.createAt">{{moment.unix(scope.row.createAt / 1000).format('Y-MM-DD HH:mm:ss')}}</span>
            <span v-else>-</span>
          </template>
      </el-table-column>
    </el-table>
    <div style="height: 80px"></div>
    <div class="paginationClass" v-if="tableData.length > 0">
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="searchData.pageNo"
      :page-size="searchData.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getFeedBackList } from '@/serve/module/feedbackManage'
import PartTitle from '@/components/Part'
const moment = require('moment')
export default {
  components: {
    PartTitle
  },
  data () {
    return {
      moment: moment,
      searchData: { // 搜索数据
        pageNo: 1, // 当前页
        pageSize: 20 // 每页条数
      },

      totalPage: 1,
      tableData: []

    }
  },
  created () {
    this.getFeedBackList(this.searchData)
  },
  methods: {
    handleSizeChange (val) {
      this.searchData.pageSize = val
      this.getFeedBackList(this.searchData)
    },
    handleCurrentChange (val) {
      this.searchData.pageNo = val
      this.getFeedBackList(this.searchData)
    },
    getFeedBackList (params) {
      getFeedBackList(params).then(res => {
        if (res) {
          this.tableData = res.data.data
          this.totalPage = res.data.total
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin: 0 24px;
}
.form {
  width: 100%;
}
.el-input {
  display: inline-block;
  width: 192px;
  margin-right: 24px;
}
.content-btn {
    float: right;
    margin-bottom: 12px;
  }
.paginationClass {
background: #fff;
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 210px;
    z-index: 100;
    padding: 24px 20px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
::v-deep .el-table th.el-table__cell>.cell {
  color: #909399;
  font-weight: 400;
  font-size: 14px;
 }
.receipt-btn {
  float: right;
  margin-bottom: 10px;
}
.detail-btn {
  font-size: 14px;
  font-weight: 400;
}
</style>
