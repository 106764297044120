<template>
  <el-drawer
    title="添加医院信息"
    :visible="visible"
    :wrapperClosable="false"
    :close-on-press-escape="false"
    @close="onCancel"
    size="400px">
    <div style="margin: 24px">
      <el-form
        :model="ruleHospitalForm"
        :rules="rules"
        ref="ruleHospitalForm"
        label-position="left"
        size="small">
        <el-form-item label="医院名称" label-width="80px" prop="name">
          <el-input v-model="ruleHospitalForm.name" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="医院邀请码" label-width="102px" prop="inviteCode">
          <el-input v-model="ruleHospitalForm.inviteCode" maxlength="4"></el-input>
        </el-form-item>
      </el-form>
      <div class="line"></div>
      <div class="drawer-footer">
        <el-button class="footer-btn" @click="onCancel" size="small">取消</el-button>
        <el-button class="footer-btn" type="primary" @click="handelAdd" size="small">保存</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import { addHospital } from '@/serve/module/hospitalManage'
const moment = require('moment')
export default {
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.ruleHospitalForm = {}
        this.resetFields()
      }
    }
  },
  data () {
    return {
      moment: moment,
      ruleHospitalForm: {
        name: '',
        inviteCode: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入医院名称', trigger: 'blur' }
        ],
        inviteCode: [
          { required: true, message: '请输入医院邀请码', trigger: 'blur' },
          { min: 4, max: 4, message: '格式错误，长度在 4 个字符', trigger: 'change' },
          { pattern: /^\d+$/, message: '格式错误，请输入数字' }
        ]
      }
    }
  },
  mounted () {
  },
  methods: {
    resetFields () {
      if (this.$refs.ruleHospitalForm !== undefined) {
        this.$refs.ruleHospitalForm.resetFields()
      }
    },
    addHospital (params) {
      addHospital(params).then(res => {
        if (res.code === 200) {
          this.$message.success('保存成功')
          this.$emit('update:visible', false)
          this.$emit('addHospital', true)
        } else {
          this.$message.error(res.message)
        }
      }).catch((err) => {
        this.$message.error(err)
      })
    },
    handelAdd () {
      this.$refs.ruleHospitalForm.validate((valid) => {
        console.log(this.formData)
        if (valid) {
          this.addHospital(this.ruleHospitalForm)
        }
      })
    },
    onCancel () {
      this.$emit('update:visible', false)
    }
  }
}
</script>
<style scoped lang="scss">
.hospital-manage-content {
  margin: 0 20px;
  .content-btn {
    float: right;
    margin-bottom: 12px;
    font-size: 14px;
  }
}
::v-deep .el-drawer__header {
  font-weight: 500;
  font-size: 16px;
  color: #0F1114;
  padding: 16px 0px 16px 24px;
  margin-bottom: 0;
  box-shadow: inset 0px -1px 0px #E7E8EA;
}

.line {
  height: 1px;
  width: 400px;
  position: fixed;
  bottom: 64px;
  right: 0;
  box-shadow: inset 0px 1px 0px #E7E8EA;
}
.drawer-footer {
  position: fixed;
  bottom: 0;
  height: 48px;
  right: 20px;
}
::v-deep .el-button--text {
  font-size: 12px;
  font-weight: 400;
}
.page,.page-shadow {
  background: #fff;
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 210px;
  z-index: 100;
  padding: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
::v-deep .el-form-item {
 margin-bottom: 16px;
}
</style>
