<template>
  <!-- 分页 -->
  <div :class=" currentSizePage <=10  ? 'page':   'page-shadow'   ">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="pageSizes"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentSizePage: null
    }
  },
  props: {
    page: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default: () => {
        return [10, 20, 30, 50]
      }
    }
  },
  methods: {
    handleSizeChange (val) {
      const _this = this
      _this.currentSizePage = val
      _this.$emit('handleSizeChange', val)
    },
    handleCurrentChange (val) {
      const _this = this
      _this.$emit('handleCurrentChange', val)
    }
  }
}
</script>
<style scoped lang="scss">
.page,.page-shadow {
  background: #fff;
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 210px;
  z-index: 100;
  padding: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.page-shadow {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.12), 0px -4px 4px rgba(0, 0, 0, 0.04)
}
</style>
