<template>
  <div class="content">
    <header class="header">
      <part-title title="用户管理"></part-title>
    </header>
    <div class="search">
      <div class="column-item">
        <div class="search-item">
          <span class="search-span">患者姓名：</span>
          <el-input class="el-input" size="small" v-model="searchData.name" placeholder="请输入患者姓名" maxlength="20"></el-input>
        </div>
        <div class="search-item">
          <span class="search-span">手机号：</span>
          <el-input class="el-input" size="small" v-model="searchData.phone" placeholder="请输入手机号" maxlength="11"></el-input>
        </div>
        <div class="search-item-time">
          <span class="search-span">注册时间：</span>
          <el-date-picker
            size="small"
            v-model="timeRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            style="width:76%;">
          </el-date-picker>
        </div>
      </div>
      <div class="column-item">
        <div class="search-item">
          <span class="search-span">设备序列号：</span>
          <el-input class="el-input" size="small" v-model="searchData.deviceSn" placeholder="请输入设备序列号"></el-input>
        </div>
        <div class="search-item">
          <span class="search-span">设备类别：</span>
          <el-select class="el-input" size="small" v-model="searchData.deviceType" placeholder="请选择" clearable>
            <el-option
              v-for="item in modelTypeOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </div>
        <div class="search-item-time">
          <el-button
            @click="onReset"
            class="search-btn"
            size="small">
            重置
          </el-button>
          <el-button
            type="primary"
            @click="onSearch"
            class="search-btn"
            size="small">
            查询
          </el-button>
        </div>
      </div>
    </div>
    <el-table
    :data="tableData">
      <el-table-column
        align="center"
        fixed
        label="序号"
        type="index"
        width="50">
      </el-table-column>
      <el-table-column
        align="center"
        label="用户姓名">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="onClickUser(scope.row)">
              <span v-if="scope.row.userName" >{{scope.row.userName}}</span>
              <span v-else>-</span>
            </el-button> -->
            <div v-if="scope.row.userName && scope.row.userName.length > 0">
            <el-button style="width: 100%; margin-left: 0px" v-for="(item,index) in scope.row.userName" :key="index" type="text" @click="onClickUser(scope.row,item)">
              {{item.userName}}
            </el-button>
          </div>
          <el-button v-else type="text">-</el-button>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="用户身份">
        <template slot-scope="scope">
          <div v-if="scope.row.relationTypes && scope.row.relationTypes.length > 0">
            <span v-for="(item,index) in scope.row.relationTypes" :key="index">{{item }}
            </span>
          </div>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="phone"
        label="注册手机号">
        <template slot-scope="scope">
            <span v-if="scope.row.phone">{{scope.row.phone}}</span>
            <span v-else>-</span>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="注册来源">
        <template slot-scope="scope">
          <span v-if="scope.row.appName">{{scope.row.appName}}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="createAt"
        label="注册时间"
        min-width="100px">
          <template slot-scope="scope">
            <span v-if="scope.row.userCreateAt">{{scope.row.userCreateAt }}</span>
            <span v-else>-</span>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="患者姓名">
        <template slot-scope="scope">
          <div v-if="scope.row.patientList && scope.row.patientList.length > 0">
            <el-button style="width: 100%; margin-left: 0px" v-for="(item,index) in scope.row.patientList" :key="index" type="text" @click="onClickPatient(item)">
              {{item.patientName}}
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="品牌型号">
        <template slot-scope="scope">
          <div v-if="scope.row.patientList && scope.row.patientList.length > 0">
            <div v-for="(item,index) in scope.row.patientList" :key="index">
              <div v-for="(i,j) in item.deviceList" :key="j">
                <span v-if="i.brandName && i.modelName"> {{i.brandName + i.modelName }}</span>
                <span v-else>-</span>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="设备序列号">
        <template slot-scope="scope">
          <div v-if="scope.row.patientList && scope.row.patientList.length > 0">
            <div v-for="(item,index) in scope.row.patientList" :key="index">
              <div v-for="(i,j) in item.deviceList" :key="j">
                <span v-if="i.deviceSn"> {{i.deviceSn}}</span>
                <span v-else>-</span>
            </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="createAt"
        label="植入日期">
        <template slot-scope="scope">
          <div v-if="scope.row.patientList && scope.row.patientList.length > 0">
            <div v-for="(item,index) in scope.row.patientList" :key="index">
              <div v-for="(i,j) in item.deviceList" :key="j">
                <span v-if="i.implantTime">{{moment.unix(i.implantTime / 1000).format('Y-MM-DD')}}</span>
                <span v-else>-</span>
            </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="etiology"
        label="植入医院"
        min-width="120px">
        <template slot-scope="scope">
          <div v-if="scope.row.patientList && scope.row.patientList.length > 0">
            <div v-for="(item,index) in scope.row.patientList" :key="index">
              <div v-for="(i,j) in item.deviceList" :key="j">
                <span v-if="i.hospitalName"> {{i.hospitalName}}</span>
                <span v-else>-</span>
            </div>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 80px"></div>
  </div>
</template>

<script>
import { getUserList } from '@/serve/module/userManage'
import PartTitle from '@/components/Part'
import { getDeviceTypeList } from '@/serve/module/common'
const moment = require('moment')
export default {
  components: {
    PartTitle
  },
  data () {
    return {
      moment: moment,
      searchData: { // 搜索数据
      },

      tableData: [],
      modelTypeOptions: [],
      selectDate: '',
      timeRange: '',
      // 日期选择范围在一个月内,小于今天
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime()
          if (maxDate) {
            this.selectDate = ''
          }
        },
        disabledDate: time => {
          // 什么也没有选择的状态直接限制小于今天
          return time.getTime() > Date.now() - 8.64e7
        }
      }
    }
  },
  created () {
    this.getDeviceTypeList()
  },
  methods: {
    getDeviceTypeList () {
      getDeviceTypeList().then(res => {
        if (res.data) {
          this.modelTypeOptions = res.data
        }
      })
    },
    handleSizeChange (val) {
      this.searchData.pageSize = val
      this.getUserList(this.searchData)
    },
    handleCurrentChange (val) {
      this.searchData.pageNo = val
      this.getUserList(this.searchData)
    },
    getUserList (params) {
      getUserList(params).then(res => {
        if (res.code === 200) {
          this.tableData = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    onSearch () {
      if (this.timeRange) {
        this.searchData.startTime = this.timeRange[0] + '00:00:00'
        this.searchData.endTime = this.timeRange[1] + '23:59:59'
      } else {
        this.$delete(this.searchData, 'startTime')
        this.$delete(this.searchData, 'endTime')
      }
      if (!this.searchData.name) {
        this.$delete(this.searchData, 'name')
      }
      if (!this.searchData.phone) {
        this.$delete(this.searchData, 'phone')
      }
      if (!this.searchData.deviceSn) {
        this.$delete(this.searchData, 'deviceSn')
      }
      if (!this.searchData.deviceType && this.searchData.deviceType !== 0) {
        this.$delete(this.searchData, 'deviceType')
      }
      this.getUserList(this.searchData)
    },
    onClickPatient (val) {
      this.$router.push({
        name: 'patientDetails',
        query: {
          patientId: val.patientId
        }
      })
    },
    onClickUser (row, userInfo) {
      // 用户id
      if (row.userId) {
        this.$router.push({
          name: 'userDetails',
          query: {
            userId: row.userId,
            bizType: userInfo.bizType
          }
        })
      }
    },
    onReset () {
      this.searchData = {}
      this.timeRange = null
    },
    inputName () {
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin: 0 24px;
}
.paginationClass {
background: #fff;
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 210px;
    z-index: 100;
    padding: 24px 20px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
::v-deep .el-table th.el-table__cell>.cell {
  color: #909399;
  font-weight: 400;
  font-size: 14px;
 }

 .search {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    .column-item {
      display: flex;
      justify-content:space-between;
      margin-bottom: 8px;
      .search-item {
        flex: 1;
        width: 100%;
        margin-right: 24px;
        .el-input {
          width: 76%;
        }
        .search-span {
          display:inline-block;
          width:84px;
          font-weight: 400;
          line-height: 32px;
          color: #606266;
        }
      }
      .search-item-time {
        flex: 1.2;
      }
        .el-span {
          display:inline-block;
          width: 24%;
          font-weight: 400;
          line-height: 32px;
          color: #606266;
        }
    }
  }

</style>
