const moment = require('moment')
export function dataToFile (res) {
  const blob = new Blob([res])
  const fileName = `二维码${moment.unix(new Date().getTime() / 1000).format('Y-MM-DD HH:mm:ss')}.jpg`
  const elink = document.createElement('a')

  elink.download = fileName
  elink.style.display = 'none'
  elink.href = window.URL.createObjectURL(blob)
  document.body.appendChild(elink)
  elink.click()
  window.URL.revokeObjectURL(elink.href) // 释放URL 对象
  document.body.removeChild(elink)
}
