<template>
  <div class="userDetails-box">
    <el-scrollbar style="height: 100%">
      <div class="box-title">
        <span class="title-one">医院管理 / </span>
        <span class="title-two">医生管理 - {{hospitalName}}</span>
      </div>
      <div class="search-content">
        <el-button size="small" @click="handleBack">返回</el-button>
        <el-button size="small" type="primary" @click="openAccount">医生开户</el-button>
      </div>
      <div class="box-patientInfo">
        <el-table
        :data="tableData">
          <el-table-column
            align="center"
            fixed
            label="序号"
            type="index"
            width="50">
          </el-table-column>
          <el-table-column
            align="center"
            label="医生名称">
            <template slot-scope="scope">
              <span v-if="scope.row.doctorName" >{{scope.row.doctorName}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="手机号">
            <template slot-scope="scope">
              <span v-if="scope.row.phone" >{{scope.row.phone}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="账号">
            <template slot-scope="scope">
              <span v-if="scope.row.username" >{{scope.row.username}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="创建人">
            <template slot-scope="scope">
                <span v-if="scope.row.creatorName">{{scope.row.creatorName}}</span>
                <span v-else>-</span>
              </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="开户时间"
            width="150px">
            <template slot-scope="scope">
              <span v-if="scope.row.createAt">{{moment.unix(scope.row.createAt / 1000).format('Y-MM-DD HH:mm:ss')}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="账号状态">
            <template slot-scope="scope">
              <el-switch
              v-model="scope.row.isEnable"
              :active-value="1"
              :inactive-value="0"
              @change="onChangeStatus(scope.row)">
            </el-switch>
            </template>
          </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="120">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="onEdit(scope.row)">
              编辑
            </el-button>
          </template>
        </el-table-column>
        </el-table>
      </div>
      <div style="height: 80px;"></div>
        <!-- 分页 -->
      <Page
          @handleSizeChange="sizeChange"
          @handleCurrentChange="pageChange"
          :current-page="params.pageNo"
          :page-size="params.pageSize"
          :total="total"
      ></Page>
      <DoctorOA :visible.sync="visibleDoctorOA" :isEdit="isEdit" :doctorData="doctorData" @saveSuccess="saveSuccess"></DoctorOA>
    </el-scrollbar>
  </div>
</template>
<script>
import DoctorOA from './Page_DoctorOA/index.vue'
import Page from '@/components/Page'
import { getDoctorList, changeDoctorAccount } from '@/serve/module/hospitalManage'
const moment = require('moment')
export default {
  components: {
    DoctorOA,
    Page
  },
  data () {
    return {
      moment: moment,
      tableData: [],
      hospitalName: this.$route.query.name,
      params: {
        hospitalId: this.$route.query.id,
        pageNo: 1,
        pageSize: 20
      },
      visibleDoctorOA: false,
      isEdit: false,
      doctorData: {},
      total: 0
    }
  },
  created () {
    this.getDoctorList()
  },
  methods: {
    handleBack () {
      this.$router.push({ name: 'hospitalManage' })
    },
    openAccount () {
      this.visibleDoctorOA = true
      this.isEdit = false
    },
    onEdit (row) {
      this.visibleDoctorOA = true
      this.isEdit = true
      this.doctorData = row
    },
    saveSuccess () {
      console.log('医生开户成功')
      this.getDoctorList()
    },
    onChangeStatus (row) {
      if (row.isEnable === 1) {
      // 启用
        row.isEnable = 0
        this.changeDoctorAccount('enable', row.id)
      } else {
      // 停用
        row.isEnable = 1
        this.$confirm('确定冻结医生账号？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.isEnable = 0
          this.changeDoctorAccount('disable', row.id)
        }).catch(() => {
        //
        })
      }
    },
    getDoctorList () {
      getDoctorList(this.params).then(res => {
        if (res) {
          console.log('医生列表：', res.data)
          this.tableData = res.data.data
          this.total = res.data.total
        }
      })
    },
    changeDoctorAccount (status, id) {
      changeDoctorAccount({
        status: status,
        id: id
      }).then(res => {
        if (res.code === 200) {
          const message = status === 'enable' ? '启用成功' : '停用成功'
          this.$message.success(message)
          this.getDoctorList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    sizeChange (val) {
      this.params.pageSize = val
      this.getDoctorList()
    },
    pageChange (val) {
      this.params.pageNo = val
      this.getDoctorList()
    }
  }
}
</script>
<style scoped lang="scss">
.userDetails-box {
  .box-title {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 14px;
    .title-one {
      color: #909399;
    }
    .title-two {
      color: #131414;
    }
  }
}
.search-content {
  float: right;
  margin-bottom: 12px;
}
</style>
