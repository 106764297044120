import { get, post } from '@/serve/axios'

// 产品型号管理
// 1. 产品型号管理列表
export const getModelList = (params) => get(`admin/v1/device/brand-model/list?pageNo=${params.pageNo}&pageSize=${params.pageSize}`)

// 2. 新增型号设备
export const addModelDevice = (params) => post('admin/v1/device/brand-model/add', params)

// 3. 获取型号设备详情
export const getModelDeviceDetail = (modelId) => get(`admin/v1/device/brand-model/detail?modelId=${modelId}`)

// 4. 编辑产品型号
export const editModelDevice = (params) => post('admin/v1/device/brand-model/edit', params)
