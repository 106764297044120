<template>
  <div class="patient-list">
  <el-drawer
    :title="isEdit ? '编辑医生信息':'新增医生信息'"
    size="420px;"
    :visible="visible"
    @close="handleClose"
    :wrapperClosable="false"
    :close-on-press-escape="false">
    <el-form :model="ruleFormDoctor" ref="ruleFormDoctor" size="small" label-position="left" status-icon :rules="rules"  label-width="62px" class="doctor-info">
      <el-form-item label="姓名" prop="doctorName">
        <el-input class="form-ipt" placeholder="请输入医生姓名" v-model="ruleFormDoctor.doctorName"></el-input>
      </el-form-item>
      <el-form-item label="账号" prop="username">
        <el-input class="form-ipt" placeholder="请输入登录账号" v-model="ruleFormDoctor.username">
        </el-input>
      </el-form-item>
      <el-form-item label="密码" prop="passwordTest">
        <el-input class="form-ipt" placeholder="请输入登录密码" :type="isEdit? 'password' : 'text'" @focus='handlerIptClick' :readonly="readonly" v-model="ruleFormDoctor.passwordTest" @input="onChangePassword"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input class="form-ipt" placeholder="请输入手机号" v-model="ruleFormDoctor.phone" maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" @input="onInputVerifyCode"></el-input>
      </el-form-item>
    </el-form>
    <div class="demo-drawer__footer">
      <el-button class="footer-btn" @click="handleClose()">取消</el-button>
      <el-button class="footer-btn" type="primary" @click="handleSave()">保存</el-button>
    </div>
  </el-drawer>
  <el-dialog
    title="请确认开户信息"
    :visible.sync="dialogVisible"
    width="30%">
      <span>姓名：{{ruleFormDoctor.doctorName}}</span>
      <br>
      <span>账号：{{ruleFormDoctor.username}}</span>
      <br>
      <span>手机号：{{ruleFormDoctor.phone}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDoctor()">确 定</el-button>
      </span>
  </el-dialog>
  </div>
</template>

<script>
// import Vue from 'vue'
import { getDoctorInfo, addDoctor, editDoctor } from '@/serve/module/hospitalManage'
import md5 from 'js-md5'
// import { getEditDoctorInfo } from '@/service/module/common'
export default {
  components: {

  },
  data () {
    const pattern = /^1\d{10}$/
    const validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else if (!pattern.test(value) && this.isChangePhone === 1) {
        callback(new Error('格式有误，请输入以1开头的11位数字'))
        return false
      } else {
        callback()
        return true
      }
    }
    return {
      ruleFormDoctor: {},
      rules: {
        doctorName: [
          { required: true, message: '请输入医生姓名', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'change' },
          { pattern: /^[\u4E00-\u9FA5A-Za-z·]+$/, message: '请输入中文/英文/·' }
        ],
        username: [
          { required: true, message: '请输入登录账号', trigger: 'blur' }, { min: 1, max: 32, message: '长度在 1 到 32 个字符', trigger: 'blur' }, { pattern: /^[A-Za-z\d]+$/, message: '账号格式有误，请输入登录账号，例如：zhenxy123' }
        ],
        passwordTest: [
          { required: true, message: '请输入登陆密码', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 8 到 20 个字符', trigger: 'blur' },
          { pattern: /(?!.*\s)(?!^[0-9]+$)(?!^[^A-Za-z0-9]+$)(?!^[^A-Za-z.*\s]+$)(?!^[^0-9.*\s]+$)^.{8,20}$/, message: '密码格式有误，请输入8-20位英文、数字、符号（.和_）' }
        ],
        phone: [
          { required: true, message: '请输入手机号' },
          { validator: validatePhone, trigger: 'blur' }
        ]
      },
      dialogVisible: false,
      disabledVerCodeBtn: false,

      phone: '',
      readonly: true,

      isDisabledVerifyCode: true,

      timer: null,

      isChangePassword: 0,
      isChangePhone: 0
    }
  },
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    isEdit: {
      default: false,
      type: Boolean
    },
    doctorData: {
      type: Object,
      default: null
    }
  },
  watch: {
    visible (val) {
      if (val) {
        clearTimeout(this.timer)
        this.disabledVerCodeBtn = false
        this.isDisabledVerifyCode = true
        this.isChangePhone = 0
        if (this.$refs.ruleFormDoctor) {
          this.$refs.ruleFormDoctor.resetFields()
        }
        if (this.isEdit) {
          this.getDoctorInfo(this.doctorData.id)
        } else {
          this.ruleFormDoctor = {
            doctorName: '',
            username: '',
            password: '',
            passwordTest: ''
          }
        }
      }
    }
  },
  methods: {
    handlerIptClick () {
      this.readonly = false
    },
    handleClose () {
      this.$refs.ruleFormDoctor.resetFields()
      this.$emit('update:visible', false)
    },
    handleSave () {
      this.$refs.ruleFormDoctor.validate((valid) => {
        if (valid) {
          this.createDoctorAccount()
          console.log('保存！')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    createDoctorAccount () {
      this.ruleFormDoctor.hospitalId = this.$route.query.id
      if (this.isEdit) {
        // 没改密码
        if (this.isChangePassword === 0) {
          this.ruleFormDoctor.password = ''
        } else {
          if (this.ruleFormDoctor.passwordTest) {
            this.ruleFormDoctor.password = md5((this.ruleFormDoctor.passwordTest).toString())
          }
        }
        delete this.ruleFormDoctor.passwordTest
        if (this.isChangePhone === 0) {
          delete this.ruleFormDoctor.phone
        }
        editDoctor(this.ruleFormDoctor).then(res => {
          if (res.code === 200) {
            this.$emit('update:visible', false)
            this.$emit('saveSuccess', false)
            this.$message.success('修改成功')
          } else {
            this.$set(this.ruleFormDoctor, 'passwordTest', 'pW000000')
            this.$message.error(res.message)
          }
        }).catch((err) => {
          this.$set(this.ruleFormDoctor, 'passwordTest', 'pW000000')
          this.$message.error(err)
        })
      } else {
        this.dialogVisible = true
      }
    },
    onChangePassword (val) {
      this.$forceUpdate()
      if (this.isEdit) {
        this.isChangePassword = 1
        this.$set(this.ruleFormDoctor, 'password', val)
      }
    },
    onInputVerifyCode (val) {
      this.$forceUpdate()
      if (this.isEdit) {
        this.isChangePhone = 1
        this.$set(this.ruleFormDoctor, 'phone', val)
      }
    },
    getDoctorInfo (id) {
      getDoctorInfo(id).then(res => {
        if (res) {
          this.ruleFormDoctor = res.data
          this.ruleFormDoctor.doctorId = res.data.id
          this.ruleFormDoctor.isChangePassword = 0
          this.$set(this.ruleFormDoctor, 'passwordTest', 'pW000000')
          console.log('ruleFormDoctor:', this.doctorData)
        }
      })
    },
    addDoctor () {
      let passWord = ''
      if (this.ruleFormDoctor.passwordTest) {
        this.ruleFormDoctor.password = md5((this.ruleFormDoctor.passwordTest).toString())
        passWord = this.doctorData.passwordTest
      }
      delete this.ruleFormDoctor.passwordTest
      addDoctor(this.ruleFormDoctor).then(res => {
        if (res.code === 200) {
          this.$emit('update:visible', false)
          this.$emit('saveSuccess', false)
          this.$message.success('保存成功')
          this.dialogVisible = false
        } else {
          if (passWord) {
            this.$set(this.ruleFormDoctor, 'passwordTest', 'pW000000')
          }

          this.$message.error(res.message)
        }
      }).catch((err) => {
        this.$message.error(err)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.demo-drawer__footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background-color: white;
}
.footer-btn {
  width: 74px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}
.form-ipt {
  // width: 426px;
  height: 32px;
  line-height: 32px;
}
.form-ipt-verification-code {
  width: 179px;
}
.form-btn-verification-code {
  width: 102px;
  height: 32px;
}
::v-deep .el-drawer__header {
  font-weight: 500;
  font-size: 16px;
  color: #0F1114;
  padding: 16px 0px 16px 24px;
  margin-bottom: 0;
  box-shadow: inset 0px -1px 0px #E7E8EA;
}

// ::v-deep .el-input {
//   // width: 426px;
//   height: 32px;
//   line-height: 32px;
// }
::v-deep .el-form-item__label  {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  padding: 0 8px 0 0;
  text-align: left;
}
::v-deep .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item{
  margin-bottom: 24px;
}
.doctor-info {
  margin: 24px;
}
</style>
