<template>
  <div>
    <el-dialog
      title="用户基本信息"
      :visible="visible"
      width="1300px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :lock-scroll="true"
      @close="cancel"
    >
      <div class="content">
        <el-table
          :data="userBasicInfo"
          style="width: 100%">
          <el-table-column
            label="序号"
            type="index"
            width="50">
          </el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            width="70">
          </el-table-column>
          <el-table-column
            label="性别"
            width="50">
            <template slot-scope="scope">
            <span v-if="scope.row.gender">{{scope.row.gender === 1 ? '男':'女'}}</span>
            <span v-else>-</span>
          </template>
          </el-table-column>
          <el-table-column
            prop="age"
            label="年龄"
            width="50">
          </el-table-column>
          <el-table-column
            prop="idNumber"
            label="身份证号"
            width="170">
          </el-table-column>
          <el-table-column
            label="地区"
            width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.province && scope.row.city && scope.row.district">
              {{scope.row.province + scope.row.city + scope.row.district}}
              </span>
              <span v-else>-</span>
        </template>
          </el-table-column>
          <el-table-column
            label="住址">
            <template slot-scope="scope">
              <span v-if="scope.row.addressDetail">
                {{scope.row.addressDetail}}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            label="注册来源"
            width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.appName"> {{ scope.row.appName }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="followUpPhone"
            label="随访手机号"
            width="110">
            <template slot-scope="scope">
            <span v-if="scope.row.followUpPhone" >{{scope.row.followUpPhone}}</span>
            <span v-else>-</span>
          </template>
          </el-table-column>
          <el-table-column
            label="HFM手机号"
            width="110">
            <template slot-scope="scope">
            <span v-if="scope.row.hfmPhone" >{{scope.row.hfmPhone}}</span>
            <span v-else>-</span>
          </template>
          </el-table-column>
          <el-table-column
            label="ICM手机号"
            width="110">
            <template slot-scope="scope">
            <span v-if="scope.row.icmPhone" >{{scope.row.icmPhone}}</span>
            <span v-else>-</span>
          </template>
          </el-table-column>
          <el-table-column
            label="ICM预留手机号"
            width="120">
            <template slot-scope="scope">
            <span v-if="scope.row.icmReservedPhone" >{{scope.row.icmReservedPhone}}</span>
            <span v-else>-</span>
          </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="small">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const moment = require('moment')
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    userBasicInfo: {
      type: Array
    }

  },
  data () {
    return {
      moment: moment
    }
  },
  methods: {
    cancel () {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  .self-defined-classname-test {
  margin-left: 67px;
}
}

</style>
