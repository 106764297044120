<template>
  <div class="login-box flex">
    <div :class=" loginError ? 'login-info-error__box': 'login-info__box'">
      <h1 class="info-title">
        欢迎登录
      </h1>
      <div class="content">
        <div id="self_defined_element" class="self-defined-classname"></div>
      </div>
      <!-- <el-form class="info-form" ref="form" :model="loginForm">
        <el-form-item>
          <el-input v-model="loginForm.username" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="请输入8~20位字母、数字或特殊字符的组合" type="password" v-model="loginForm.password" show-password></el-input>
        </el-form-item>
        <span v-if="passwordErr" style="color: #d52e2e">{{errorPrompt}}</span>
      </el-form>
      <el-button class="login-btn" type="primary" :disabled="disabledLogin"  @click="doLogin">登 录</el-button> -->
      <p class="prompt">请使用钉钉扫描二维码登录</p>
    </div>
  </div>
</template>
<script>
import { DDLogin } from '@/serve/module/common'
import md5 from 'js-md5'
export default {
  data () {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      passwordErr: false,
      disabledLogin: false,
      errorPrompt: '',

      loginError: false,

      redirect_uri: '',
      client_id: 'dingseke74scmkhnhwdj'

    }
  },
  mounted () {
    console.log('step5')
    if (process.env.NODE_ENV === 'test') {
      this.redirect_uri = 'http://test-admin.ciedtracker.com/login'
    } else if (process.env.NODE_ENV === 'production') {
      this.redirect_uri = 'https://admin.ciedtracker.com/login'
    } else {
      this.redirect_uri = 'http://test1-admin.ciedtracker.com:8080/login'
    }
    this.createQr()
  },
  methods: {
    DDLogin (authCode) {
      console.log('step2:', authCode)
      DDLogin(authCode).then(res => {
        if (res.code === 200) {
          this.$message.success('登录成功')
          if (res.data) {
            const token = res.data.token
            sessionStorage.setItem('Authorization', token)
            this.loginError = false
            if (token) {
              this.$router.replace({
                path: '/userManage'
              })
            }
          }
          console.log('token：', res.data.token)
        } else {
          this.$message.error(res.message)
        }
      }).catch(() => {
        this.$message.error(res.message)
      })
    },
    createQr () {
      window.DTFrameLogin(
        {
          id: 'self_defined_element',
          width: 300,
          height: 260
        },
        {
          redirect_uri: encodeURIComponent(this.redirect_uri),
          client_id: this.client_id,
          scope: 'openid',
          response_type: 'code',
          prompt: 'consent'
        },
        (loginResult) => {
          const { redirectUrl, authCode, state } = loginResult
          this.redirectUrl = redirectUrl
          const token = sessionStorage.getItem('Authorization')
          if (!token) this.DDLogin(authCode)
        },
        (errorMsg) => {
          alert(`Login Error: ${errorMsg}`)
        }
      )
    }
  },
  beforeDestroy () {
    console.log('step2.5')
  }
}
</script>

<style scoped lang="scss">
  @import "src/style/common.scss";
  .login-box {
    overflow: hidden;
    @include domSize(100%, 100%);
    align-items: center;
    justify-content: flex-end;
    position: relative;
    background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/LoginBackground.png") no-repeat;
    background-size: cover;
    .login-info__box,.login-info-error__box {
      @include domSize(332px, 380px);
      @include bgColor(#fff);
      margin-right: 160px;
      border-radius: 10px;
      padding-top: 40px;
      .info-title {
        @include fs(#000, 26px, 700);
        line-height: 30.32px;
        margin-left: 114px;
      }
      .info-subtitle {
        @include fs(#A3b2c2, 14px, 400);
        line-height: 16.32px;
        margin-bottom: 12px;
      }
      .info-form {
        margin-bottom: 24px;
      }
      .login-btn {
        width: 100%;
        height: 40px;
        margin: 0 auto;
        @include fs(#fff, 14px, 700);
      }
    }
    .login-info-error__box {
      height: 330px;
    }
    .el-form-item {
      margin-bottom: 16px !important;
    }
    .form-ver-code-ipt {
      width: 223px;
    }
    .form-ver-code-btn {
      width: 100%;
    }

    .content {
        flex: 1;
      .self-defined-classname {
        margin-left: 15px;
      }
    }
  .prompt {
      color: #A3B2C2;
      font-weight: 400;
      font-size: 12px;
      text-align: center;
    }
}
</style>
