<template>
  <table class="home-bt-table" >
    <Loading v-if="isLoading" />
    <tbody v-else-if="Object.keys(homeBtDevice).length !== 0">
    <tr>
      <th>设备类型</th>
      <td>家用蓝牙数据传输器</td>
    </tr>
    <tr>
      <th>设备序列号</th>
      <td>{{ homeBtDevice.gatewaySn }}</td>
    </tr>
    <tr>
      <th>蓝牙mac地址</th>
      <td>{{ homeBtDevice.gatewayAddr }}</td>
    </tr>
    <tr>
      <th>已配置的家庭WiFi名称</th>
      <td>{{ homeBtDevice.wifiName }}</td>
    </tr>
    <tr>
      <th>网关状态</th>
      <td>
        {{ gwStatusText }},  更新时间 {{ gwUpdateAtText }}
        <span class="clickable-text" @click="updateStatus">刷新</span>
      </td>
    </tr>
    <tr>
      <th>绑定日期</th>
      <td>{{ formatDate(homeBtDevice.bindTime) }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import { formatDate } from '@/utils/dateUtils'
import Loading from '@/components/Loading.vue'

export default {
  name: 'HomeBTTable',
  components: { Loading },
  props: {
    homeBtDevice: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // gwStatus: this.homeBtDevice.status, // 网关在线状态
      // gwOnlineAt: this.homeBtDevice.onlineAt, // 网关在线时间
      // gwOfflineAt: this.homeBtDevice.offlineAt, // 网关离线时间
      // gatewaySn: '' // 网关SN号
    }
  },
  computed: {
    /* 设备状态 0-未激活 1-在线 2-离线 */
    gwStatusText () {
      switch (this.homeBtDevice.status) {
        case 1:
          return '在线'
        case 2:
          return '离线'
        default:
          return '未知'
      }
    },
    gwUpdateAtText () {
      switch (this.homeBtDevice.status) {
        case 1:
          return formatDate(this.homeBtDevice.onlineAt)
        case 2:
          return formatDate(this.homeBtDevice.offlineAt)
        default:
          return '未知'
      }
    }
  },
  // watch: {
  //   homeBtDevice (newVal) {
  //     this.gwStatus = newVal.gwStatus
  //   }
  // },
  methods: {
    formatDate,
    updateStatus () {
      // 在这里添加更新状态的逻辑
      console.log('更新状态')
      this.$emit('updateStatus')
    }
  }
}
</script>

<style scoped>
.home-bt-table {
  border-collapse: collapse; /* 合并边框 */
  width: 100%;
  border: 1px solid #ddd; /* 添加外边框 */
  align-self: flex-start; /* 确保表格高度独立 */
}

.home-bt-table th,
.home-bt-table td {
  padding: 8px; /* 添加内边距 */
  border: none; /* 去掉单元格边框 */
  height: 38px; /* 设置单元格高度 */
}

.home-bt-table tr {
  height: 40px; /* 设置单元格高度 */
}

.home-bt-table th {
  background-color: #f5f6f7; /* 表头背景色 */
  text-align: left; /* 表头文字左对齐 */
  font-size: 14px; /* 表头文字字号 */
  font-weight: 420; /* 表头文字字重 */
  line-height: 22px; /* 表头文字行高 */
  color: #51565D; /* 表头文字颜色 */
  width: 170px; /* 设置表头宽度 */
}

.clickable-text {
  color: #3498db; /* 淡蓝色 */
  cursor: pointer; /* 鼠标悬停时显示为手指形状 */
  margin-left: 10px; /* 可选：增加一些左边距 */
}

.clickable-text:hover {
  text-decoration: underline; /* 鼠标悬停时显示下划线 */
}

</style>
