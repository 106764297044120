<template>
  <div class="layout">
    <the-header></the-header>
    <div class="layout-box">
      <the-slide :menu-list="menuList"></the-slide>
        <div class="container">
          <!--      <theTab></theTab>-->
          <!-- <Breadcrumb></Breadcrumb> -->
          <div class="main">
            <el-scrollbar style="height: 100%">
            <router-view></router-view>
            </el-scrollbar>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import TheHeader from './TheHeader'
// import Breadcrumb from '@/components/Breadcrumb'
import TheSlide from './TheSlide'
import menu from '@/utils/menu'
// import from ''
export default {
  name: 'Layout',
  components: {
    TheHeader,
    TheSlide
    // Breadcrumb
  },
  data () {
    return {
      menuList: []
    }
  },
  async created () {
    const res = menu
    // const [err, res] = await this.$to(getMenuList())
    console.log(res)
    this.menuList = res.data
    // if (err) {
    //   throw new Error(err)
    // }
    // try {
    //   const res = await getMenuList()
    //
    //
    // } catch (e) {
    //   alert(e)
    // }
  }
}
</script>

<style scoped lang="scss">
  $layout-background: #f5f7f9;
  .layout {
    height: 100%;
    background: #fff;
    position: relative;
    /*display: flex;*/
    /*flex-direction: column;*/
    .layout-box {
      display: flex;
      /*flex: 1;*/
      height: calc(100% - 60px);
    }
    .container {
      overflow: hidden;
      flex: 1;
      padding-top: 21.5px;
      .main {
        width: 100%;
        min-width: 980px;
        height: calc(100%);
        background: #fff;
        /*padding: 0px 12px 0;*/
        .el-scrollbar__wrap    {
          overflow-x: hidden!important;
        }
      }
    }
    .el-menu {
      border-right: none;
    }
  }
  .el-menu {
    border-right: none;
  }
</style>
