<template>
  <div class="content">
    <header class="header">
    <!-- 顶部 -->
    <part-title title="小程序记录"></part-title>
  </header>
    <el-table
    :data="tableData">
      <el-table-column
        align="center"
        fixed
        label="序号"
        type="index"
        width="50">
      </el-table-column>
      <el-table-column
        align="center"
        prop="createAt"
        label="上传时间">
          <template slot-scope="scope">
            <span>{{moment.unix(scope.row.createAt / 1000).format('Y-MM-DD HH:mm:ss')}}</span>
            <span>{{scope.row.time}}</span>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="deviceSn"
        label="设备序列号">
          <template slot-scope="scope">
            <span>{{scope.row.deviceSn}}</span>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="data"
        label="数据"
        min-width="120">
        <template slot-scope="scope">
          <div v-if="scope.row.data && scope.row.data.length>0">
            <div slot="content">
              <span v-for=" (data,index) in scope.row.data" :key="index">
                {{data }}
              </span>
            </div>
          </div>
          <div v-else>-</div>
        </template>
        <!-- <template slot-scope="scope">
          <el-button v-if="scope.row.data" type="text" @click="scope.row.data1 === scope.row.data">查看数据</el-button>
          <div v-if="scope.row.data1">
            <span v-for=" (data,index) in scope.row.data1" :key="index">
            {{data}}、
            </span>
           </div>
        </template> -->
      </el-table-column>
      <el-table-column
        align="center"
        prop="phone"
        label="手机号">
        <template slot-scope="scope">
          <span v-if="scope.row.phone">{{scope.row.phone.substring(0, 3)}}****{{scope.row.phone.substring(7, 11) }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="symptoms"
        label="症状">
          <template slot-scope="scope">
           <div v-if="scope.row.symptoms && scope.row.symptoms.length>0">
            <span v-for="symptom in scope.row.symptoms" :key="symptom">
            {{symptomOpts.find(o => o.code === symptom).text}}、
            </span>
           </div>
           <div v-else>-</div>
          </template>
      </el-table-column>
    </el-table>
    <div style="height: 80px"></div>
    <div class="paginationClass" v-if="tableData.length > 0">
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="searchData.pageNo"
      :page-size="searchData.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getList } from '@/serve/module/common'
import { symptomOpts } from '@/utils/enum'
import PartTitle from '@/components/Part'
const moment = require('moment')
export default {
  components: {
    PartTitle
  },
  data () {
    return {
      moment: moment,
      symptomOpts,
      searchData: { // 搜索数据
        pageNo: 1, // 当前页
        pageSize: 20 // 每页条数
      },

      totalPage: 1,
      tableData: [],
      dataString: '',
      doctorInfo: {},
      circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      menuFlag: true

    }
  },
  created () {
    this.getList(this.searchData)
  },
  filters: {
    // 当标题字数超出时，超出部分显示’...‘。此处限制超出8位即触发隐藏效果
    ellipsis (value) {
      if (!value) return ''
      if (value.length > 8) {
        return value.slice(0, 8) + '...'
      }
      return value
    }
  },
  methods: {
    handleSizeChange (val) {
      this.searchData.pageSize = val
      this.getList(this.searchData)
    },
    handleCurrentChange (val) {
      this.searchData.pageNo = val
      this.getList(this.searchData)
    },
    getList (params) {
      getList(params).then(res => {
        if (res) {
          this.tableData = res.data.data
          this.totalPage = res.data.total
        }
      })
    },
    handleData (row) {
      row.data.map(i => {
        this.dataString += i + '、'
        this.$set(row, 'dataString', this.dataString)
      })
    }
    // logout () {
    //   this.$router.replace({
    //     path: '/login'
    //   })
    //   window.sessionStorage.removeItem('Authorization')
    // }
  }
}
</script>

<style scoped lang="scss">
.content {
  .title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
  }
}
.form {
  width: 100%;
}
.el-input {
  display: inline-block;
  width: 40%;
}
.form-ipt {
  width: 206px;
  margin-top: 8px
}
.paginationClass {
background: #fff;
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 210px;
    z-index: 100;
    padding: 24px 20px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
::v-deep .el-table th.el-table__cell>.cell {
  color: #909399;
  font-weight: 400;
  font-size: 14px;
 }

 .set-popper {
  width: 431px;
}
.set-content {
  cursor: default;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.top {
    height: 40px;
    width: 80px;
    position:absolute;
    top:10px;
    right:20px;
     z-index: 999;
     background-color: #f8faff;
     border-radius: 8px;
    .top-avatar {
      display: flex;
      align-items: center;
      .showList {
        cursor: pointer;
      }
      .top-avatar_img {
        margin-right: 10px;
      }
    }
  }
</style>
