<template>
  <div>
    <el-dialog
      title="身份验证"
      width="474px"
      height="287px"
      :visible="visible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :lock-scroll="true"
      @close="cancel"
    >
      <div class="content">
        <div id="self_defined_element_test1" class="self-defined-classname-test"></div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="small">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getUserSensitiveInfo, getPatientSensitiveInfo } from '@/serve/module/userManage'
const moment = require('moment')
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Object
      // default: {}
    },
    dataName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      moment: moment,
      redirect_uri: '',
      times: 0
    }
  },
  watch: {
    visible: {
      handler (newVal) {
        // console.log('newVal:', newVal)
        console.log('tableData:', this.tableData)
        const token = sessionStorage.getItem('Authorization')
        if (process.env.NODE_ENV === 'test') {
          this.redirect_uri = 'http://test-admin.ciedtracker.com'
        } else if (process.env.NODE_ENV === 'production') {
          this.redirect_uri = 'https://admin.ciedtracker.com'
        } else {
          this.redirect_uri = 'http://test1-admin.ciedtracker.com:8080'
        }
        if (newVal) {
          this.times = 0
          this.$nextTick(function () {
            window.DTFrameLogin(
              {
                id: 'self_defined_element_test1',
                width: 300,
                height: 300
              },
              {
                redirect_uri: encodeURIComponent(`${this.redirect_uri}/userManage/userDetails?userId=${this.$route.query.userId}&authCode=${token}`),
                client_id: 'dingseke74scmkhnhwdj',
                scope: 'openid',
                response_type: 'code',
                prompt: 'consent'
              },
              (loginResult) => {
                console.log('step3:', loginResult)
                ++this.times
                const { redirectUrl, authCode } = loginResult
                this.redirectUrl = redirectUrl
                const parmas = {
                  authCode: authCode,
                  dataName: this.dataName
                }
                // 随访/HFM/ICM手机号，不管是用户还是患者，都调用用户接口
                // 身份证和icm预留手机号，不管患者还是用户，都调用患者接口
                console.log('77777:', this.times)
                //
                // if(this.$route.query.patientId && this.tableData.id === Number(this.$route.query.patientId) && this.times === 1) {

                if (this.times === 1) {
                  if (this.dataName === 'userFollowUpPhone' ||
                    this.dataName === 'userHfmPhone' ||
                    this.dataName === 'userIcmPhone') {
                    if (this.$route.query.patientId && this.tableData.id === Number(this.$route.query.patientId)) {
                      // 如果是患者
                      parmas.id = this.tableData.relationUserId ? this.tableData.relationUserId : ''
                    } else if (this.$route.query.userId && this.tableData.id === Number(this.$route.query.userId)) {
                      // 如果是用户
                      parmas.id = this.tableData.id
                    }
                    console.log('params-user:', parmas)
                    getUserSensitiveInfo(parmas).then(res => {
                      if (res.code === 200) {
                        this.$emit('sensitiveData', res.data)
                        this.cancel()
                      } else {
                        this.$message.error(res.message)
                        this.cancel()
                      }
                    }).catch(() => {
                      this.$message.error(res.message)
                    })
                  } else if (this.dataName === 'patientIcmPhone' ||
                    this.dataName === 'patientIdNumber') {
                    if (this.$route.query.patientId && this.tableData.id === Number(this.$route.query.patientId)) {
                      // 如果是患者
                      parmas.id = this.tableData.id
                    } else if (this.$route.query.userId && this.tableData.id === Number(this.$route.query.userId)) {
                      // 如果是用户
                      parmas.id = this.tableData.patientId ? this.tableData.patientId : ''
                    }
                    getPatientSensitiveInfo(parmas).then(res => {
                      if (res.code === 200) {
                        this.$emit('sensitiveData', res.data)
                        this.cancel()
                      } else {
                        this.cancel()
                        this.$message.error(res.message)
                      }
                    }).catch(() => {
                      this.$message.error(res.message)
                    })
                  }
                }
              },
              (errorMsg) => {
                alert(`Login Error: ${errorMsg}`)
              }
            )
          })
        }
      }
    }
  },
  methods: {
    cancel () {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  .self-defined-classname-test {
  margin-left: 67px;
}
}

</style>
