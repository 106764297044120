import { get, post } from '@/serve/axios'

// 1. 获取网关列表
export const getGatewayList = (params) => get('admin/v1/gateway/list', { params })

// 2. 查询网关详情
export const getGatewayDetail = (params) => get('admin/v1/gateway/detail', { params })

// 3. 获取网关状态
export const getGatewayStatus = (params) => get('admin/v1/gateway/online-state', { params })

// 4. 新增网关
export const addGateway = (params) => post('admin/v1/gateway/add', params)

// 5. 编辑网关
export const editGateway = (params) => post('admin/v1/gateway/edit', params)

// 6. 网关绑定设备
export const bindGateway = (params) => post('admin/v1/gateway/bind-device', params)

// 7. 网关解绑设备
export const unbindGateway = (params) => post('admin/v1/gateway/unbind-device', params)
