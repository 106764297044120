<template>
  <div>
    <detail-drawer :visible="isVisibleDetailModel" title="设备详情" cancelStr="关闭" :showConfirm="false" :width="400" @cancel="close">
      <div class="detail-box">
        <div class="detail-line">
          <div class="label">设备序列号：</div>
          <div class="value">{{info.deviceSn}}</div>
        </div>
        <div class="detail-line">
          <div class="label">蓝牙mac地址：</div>
          <div class="value">{{info.address}}</div>
        </div>
        <div class="detail-line">
          <div class="label">品牌型号：</div>
          <div class="value">{{info.brandName}} {{info.modelName}}</div>
        </div>
        <div class="detail-line">
          <div class="label">供应商：</div>
          <div class="value">
            <span v-if="info.gatewayType === 0">昆仑</span>
            <span v-if="info.gatewayType === 1">东胜</span>
          </div>
        </div>
        <div class="detail-line">
          <div class="label">网关状态：</div>
          <div class="value">{{info.status === 1 ? '在线' : '离线'}}， 更新时间 {{moment.unix(info.updateAt / 1000).format('YYYY-MM-DD HH:mm:ss')}}</div>
        </div>
        <div class="detail-line">
          <div class="label">已配置的家庭WiFi名称：</div>
          <div class="value">{{ info.wifiName }}</div>
        </div>
        <div class="table-label">已绑定的ICM设备：</div>
        <el-table :data="info.bindDevices">
          <el-table-column label="序号" type="index" width="50">
          </el-table-column>
          <el-table-column label="ICM设备序列号" prop="deviceSn"></el-table-column>
          <el-table-column label="绑定日期" prop="date" :formatter="formatter"></el-table-column>
        </el-table>
      </div>
    </detail-drawer>
  </div>
</template>

<script>
import { getGatewayDetail } from '@/serve/module/HMManage'
import DetailDrawer from '@/components/Drawer'
const moment = require('moment')
export default {
  components: {
    DetailDrawer
  },
  props: {
    isVisibleDetailModel: {
      type: Boolean,
      default: false
    },
    sn: {
      type: String,
      default: ''
    }
  },
  watch: {
    isVisibleDetailModel (val) {
      if (val) this.getGatewayDetail(this.sn)
      else this.info = {}
    }
  },
  data () {
    return {
      moment,
      tableData: [
        { sn: 'SN 1000009872', date: '2023-12-20' }
      ],
      info: {}
    }
  },
  methods: {
    async getGatewayDetail (sn) {
      try {
        const res = await getGatewayDetail({ sn })
        console.log('详情:', res)
        let addrFormat = ''
        this.info = res.data
        if (res.data.deviceAddr) {
          const addr = []
          for (let i = 0; i < 6; i++) {
            addr.push(res.data.deviceAddr.slice(2 * i, 2 * i + 2))
          }
          addrFormat = addr.join(':')
        } else {
          addrFormat = res.data.deviceAddr
        }
        this.info.address = addrFormat
      } catch (err) {
        this.$message({
          message: err.message,
          type: 'error'
        })
      }
    },
    formatter (row, column) {
      return moment.unix(row.bindTime / 1000).format('Y-MM-DD HH:mm:ss')
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.detail-box {
  .detail-line {
    display: flex;
    margin-bottom: 24px;
    .label {
      margin-right: 12px;
      width: 100px;
      text-align: left;
      font-weight: 500;
    }
  }
  .table-label {
    font-weight: 500;
    margin-bottom: 24px;
  }
}
</style>
