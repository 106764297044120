const menu = {
  data: [
    // {
    //   title: '用户管理',
    //   link: 'userManage',
    //   route_name: 'userManage',
    //   icon_class: 'el-icon-s-custom',
    //   id: 0
    // },
    {
      title: '医院管理',
      link: 'hospitalManage',
      route_name: 'hospitalManage',
      icon_class: 'el-icon-s-shop',
      id: 5
    },
    {
      title: '用户管理',
      link: 'userManage',
      route_name: 'userManage',
      icon_class: 'el-icon-user-solid',
      id: 0
    },
    // {
    //   title: '小程序记录',
    //   link: 'list',
    //   route_name: 'samplingRecordList',
    //   icon_class: 'el-icon-s-check',
    //   id: 1
    // },
    // {
    //   title: '蓝牙网关记录',
    //   link: 'bluetoothGatewayRecord',
    //   route_name: 'bluetoothGatewayRecord',
    //   icon_class: 'el-icon-s-promotion',
    //   id: 2
    // },
    {
      title: '无双设备管理',
      link: 'deviceManage',
      route_name: 'deviceManage',
      icon_class: 'el-icon-s-platform',
      id: 4,
      children: [
        {
          title: '产品型号管理',
          link: 'productModelManage',
          route_name: 'productModelManage',
          id: 101
        },
        // {
        //   title: '生产批号管理',
        //   link: 'verifyDataConfig',
        //   route_name: 'verifyDataConfig',
        //   id: 102
        // },
        {
          title: '固件管理',
          link: 'FirmwareManage',
          route_name: 'FirmwareManage',
          id: 103
        },
        {
          title: 'ICM设备管理',
          link: 'ICMDeviceManage',
          route_name: 'ICMDeviceManage',
          id: 104
        },
        // {
        //   title: 'HFM设备管理',
        //   link: 'verifyDataConfig',
        //   route_name: 'verifyDataConfig',
        //   id: 105
        // },
        {
          title: '家庭监护设备管理',
          link: 'homeMonitor',
          route_name: 'homeMonitor',
          id: 106
        },
        {
          title: '回执单管理',
          link: 'receiptManage',
          route_name: 'receiptManage',
          id: 107
        },
        {
          title: '设备运行日志',
          link: 'deviceOperateLogs',
          route_name: 'deviceOperateLogs',
          id: 108
        }
      ]
    },
    {
      title: '无双员工管理',
      link: 'staffManage',
      route_name: 'staffManage',
      icon_class: 'el-icon-s-custom',
      id: 6
    },
    {
      title: '用户反馈',
      link: 'feedBack',
      route_name: 'feedBack',
      icon_class: 'el-icon-s-comment',
      id: 7
    }
  ]
}
export default menu
