<template>
  <div class="add-device">
    <part-title title="添加设备信息"></part-title>
    <el-form ref="form" :model="form" label-width="120px" label-position="left">
      <el-form-item label="品牌型号" :required="true" :rules="formRule.modelCode" prop="modelCode">
        <el-select
          class="form-item__width"
          v-model="form.modelCode"
          size="small"
          placeholder="请选择">
          <el-option
            v-for="item in brandModelOptions"
            :key="item.code"
            :label="item.name"
            :value="item.code">
          </el-option>
        </el-select>
        <span class="add-brand_btn" @click="showAddModel">添加品牌型号</span>
      </el-form-item>
      <el-form-item label="设备序列号" :required="true" prop="deviceSn" :rules="formRule.deviceSn">
        <el-input class="form-item__width" v-model="form.deviceSn" size="small" placeholder="请输入设备序列号"></el-input>
      </el-form-item>
      <el-form-item label="蓝牙mac地址" :required="true">
        <el-form-item class="mac-box" v-for="i in 6" :key="i" :prop="'mac' + (i - 1)" :rules="formRule.mac">
          <el-input maxlength="2" class="mac-input" v-model="form['mac' + (i - 1)]" size="small"></el-input>
          <span v-if="i !== 6">:</span>
        </el-form-item>
      </el-form-item>
    </el-form>
    <div class="btn-box">
      <el-button type="normal" size="small" @click="goBack">返回</el-button>
      <el-button type="primary" size="small" @click="save">保存</el-button>
    </div>
    <model-info :visible.sync="showModelInfo" @addModelInfo="getDeviceBrandModelList"></model-info>
  </div>
</template>

<script>
import PartTitle from '@/components/Part'
import ModelInfo from './addModelInfo'
import { getDeviceBrandModelList } from '@/serve/module/common'
import { addICMDevice } from '@/serve/module/ICMDeviceManage'

export default {
  components: {
    PartTitle,
    ModelInfo
  },
  mounted () {
    this.getDeviceBrandModelList()
  },
  data () {
    return {
      saveFlag: true,
      form: {
        modelCode: '',
        deviceSn: '',
        mac0: '',
        mac1: '',
        mac2: '',
        mac3: '',
        mac4: '',
        mac5: ''
      },
      formRule: {
        modelCode: [{ required: true, message: '请选择', trigger: 'change' }],
        deviceSn: [
          { required: true, message: '请填写', trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9]*$/, trigger: 'blur', message: '只能输入数字或字母' }
        ],
        mac: [{ required: true, message: '请填写', trigger: 'blur' },
          { pattern: /^[a-fA-F0-9]+$/, message: '格式有误', trigger: 'blur' },
          { min: 2, max: 2, message: '2个字符', trigger: 'blur' }]
      },
      brandModelOptions: [],
      showModelInfo: false
    }
  },
  methods: {
    async getDeviceBrandModelList () {
      const res = await getDeviceBrandModelList({ type: 1 })
      console.log('res:', res)
      if (res && res.code === 200) {
        this.brandModelOptions = res.data
      }
    },
    save () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (!this.saveFlag) {
            return false
          }
          this.saveFlag = false
          console.log(this.form)
          const deviceAddr = `${this.form.mac0}${this.form.mac1}${this.form.mac2}${this.form.mac3}${this.form.mac4}${this.form.mac5}`.toLowerCase()
          try {
            const res = await addICMDevice({
              deviceAddr,
              deviceType: 1,
              deviceSn: this.form.deviceSn,
              modelCode: this.form.modelCode
            })
            this.saveFlag = true
            if (res.code === 200) {
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              this.goBack()
            } else {
              this.$message({
                message: res.message,
                type: 'error'
              })
            }
          } catch (err) {
            this.saveFlag = true
            this.$message({
              message: err.message,
              type: 'error'
            })
          }
        } else {
          this.$message({
            message: '请检查填写内容是否遗漏或有误',
            type: 'error'
          })
        }
      })
    },
    showAddModel () {
      this.showModelInfo = true
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
.add-device {
  margin: 0 24px;
  .add-brand_btn {
    color: #409EFF;
    margin-left: 16px;
    cursor: pointer;
  }
}
.form-item__width {
  width: 320px;
}
.mac-box {
  display: inline-block;
  span  {
    margin: 0 10px;
  }
}
.mac-input {
  width: 100px;
}
</style>
