<template>
  <header class="header">
    <!-- 顶部 -->
    <div class="header-logo flex flex-center">
      <img src="https://f-u-system.oss-cn-beijing.aliyuncs.com/systemLogo.svg" alt="">
      <p class="header-title">无双运营平台</p>
    </div>
    <div class="top">
      <div class="top-avatar">
        <el-avatar size="medium" :src="circleUrl" class="top-avatar_img"></el-avatar>
        <el-dropdown class="showList" @visible-change="visibleChange">
          <span class="el-dropdown-link" style="color: #304152; font-weight: 400;">
            {{username}}
            <i v-if="showExit" class="el-icon-caret-left el-icon--right"></i>
            <i v-else class="el-icon-caret-bottom el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item>
              <div @click="showInformation">
                个人信息
              </div>
            </el-dropdown-item> -->
            <el-dropdown-item>
              <div @click="logout">
                退出登录
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </header>
</template>

<script>
import { getStuffInfo } from '@/serve/module/common'
export default {
  data () {
    return {
      menuFlag: true,
      circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      username: '',
      showExit: false
    }
  },
  mounted () {
    this.getStuffInfo()
  },
  methods: {
    showInformation () {
      this.$router.push({
        path: '/information'
      })
    },
    logout () {
      this.$router.replace({
        path: '/login'
      })
      window.sessionStorage.removeItem('Authorization')
    },
    getStuffInfo () {
      getStuffInfo().then(res => {
        if (res) {
          this.username = res.data.username
        }
      })
    },
    visibleChange (val) {
      if (val) {
        this.showExit = true
      } else {
        this.showExit = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/style/common.scss';
i {
  margin-right: 30px;
}

.header {
  background-color: #fff;
  height: 60px;
  display: flex;
  /*justify-content: right;*/
  align-items: center;
  /*box-shadow:2px 2px 5px red;*/
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08), 0px 2px 6px rgba(0, 0, 0, 0.06), 0px 4px 8px 2px rgba(0, 0, 0, 0.04);
  z-index: 100;
  width: 100%;
  .header-logo {
    @include domSize(210px, 100%);
    width: 210px;
    height: 100%;
    background-color: #fff;;
    @include fs(#337ECC, 17px);
    img {
      display: block;
      // @include domSize(22px, 26px);
      margin-right: 3px;
    }
    .header-title {
      line-height: 60px;
      margin-top: 5px;
      font-weight: 500;
      font-size: 18px;
    }
  }

  .top {
    height: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    border-bottom: 1px solid rgba(243, 243, 243);
    z-index: 999;
    justify-content: flex-end;
    .top-avatar {
      display: flex;
      align-items: center;
      .showList {
        cursor: pointer;
      }
      .top-avatar_img {
        margin-right: 8px;
      }
    }
  }
}
::v-deep .el-icon--right {
  margin-left: 0px;
}
</style>
