<template>
  <div class="add-device">
    <part-title :title="title"></part-title>
    <el-form ref="form" :model="form" label-width="120px" label-position="left">
      <el-form-item label="品牌型号" :required="true" prop="modelCode" :rules="formRule.modelCode">
        <el-select
          class="form-item__width"
          v-model="form.modelCode"
          size="small"
          placeholder="请选择">
          <el-option
            v-for="item in brandModelOptions"
            :key="item.code"
            :label="item.name"
            :value="item.code">
          </el-option>
        </el-select>
        <span class="add-brand_btn" @click="showAddModel">添加品牌型号</span>
      </el-form-item>
      <el-form-item label="设备序列号" :required="true" prop="deviceSn" :rules="formRule.deviceSn">
        <el-input class="form-item__width" v-model="form.deviceSn" size="small" :disabled="type === 'edit'" placeholder="请输入设备序列号"></el-input>
      </el-form-item>
      <el-form-item label="蓝牙mac地址" :required="true">
        <el-form-item class="mac-box" v-for="i in 6" :key="i" :prop="'mac' + (i - 1)" :rules="formRule.mac">
          <el-input maxlength="2" class="mac-input" v-model="form['mac' + (i - 1)]" size="small"></el-input>
          <span v-if="i !== 6">:</span>
        </el-form-item>
      </el-form-item>
      <el-form-item label="供应商" :required="true" prop="gatewayType" :rules="formRule.gatewayType">
        <el-select
          class="form-item__width"
          v-model="form.gatewayType"
          size="small"
          placeholder="请选择">
          <el-option label="昆仑" :value="0"></el-option>
          <el-option label="东胜" :value="1"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-divider v-if="type === 'edit'"></el-divider>
    <part-title v-if="type === 'edit'" title="蓝牙网关与ICM设备的绑定设置"></part-title>
    <el-table :data="tableData" v-if="type === 'edit'">
      <el-table-column align="center" fixed label="序号" type="index" width="50"></el-table-column>
      <el-table-column prop="deviceSn" label="ICM设备序列号"></el-table-column>
      <el-table-column prop="bindTime" label="绑定日期" :formatter="formatter"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="unbindICM(scope.row)">解绑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <br>
    <el-button v-if="type === 'edit'" type="text" @click="showBindModel">绑定ICM设备</el-button>
    <div class="btn-box">
      <el-button type="normal" size="small" @click="goBack">返回</el-button>
      <el-button type="primary" size="small" @click="save">保存</el-button>
    </div>
    <model-info :visible.sync="showModelInfo" @addModelInfo="getDeviceBrandModelList"></model-info>
    <bind-drawer :visible="showBindDrawer" title="绑定ICM设备" confirmStr="保存" :width="400" @confirm="bindConfirm" @cancel="bindCancel">
      <el-form label-width="114px">
        <el-form-item label="ICM设备序列号:">
          <el-input size="small" v-model="bindSn" placeholder="请输入ICM设备序列号"></el-input>
        </el-form-item>
      </el-form>
    </bind-drawer>
  </div>
</template>

<script>
import PartTitle from '@/components/Part'
import ModelInfo from './addModelInfo'
import Drawer from '@/components/Drawer'
import { getDeviceBrandModelList } from '@/serve/module/common'
import { addGateway, bindGateway, getGatewayDetail, unbindGateway, editGateway } from '@/serve/module/HMManage'
const moment = require('moment')
export default {
  components: {
    PartTitle,
    ModelInfo,
    BindDrawer: Drawer
  },
  mounted () {
    const { type, sn } = this.$route.query
    this.type = type
    this.title = type === 'add' ? '增加设备信息' : '编辑设备信息'
    this.getDeviceBrandModelList()
    if (sn && type === 'edit') {
      this.currentSn = sn
      this.getGatewayDetail(sn)
    }
  },
  data () {
    return {
      moment,
      type: '',
      title: '',
      tableData: [],
      form: {
        modelCode: '',
        deviceSn: '',
        gatewayType: '',
        mac0: '',
        mac1: '',
        mac2: '',
        mac3: '',
        mac4: '',
        mac5: ''
      },
      formRule: {
        gatewayType: [{ required: true, trigger: 'change', message: '请选择供应商' }],
        modelCode: [{ required: true, trigger: 'change', message: '请选择品牌型号' }],
        deviceSn: [
          { required: true, trigger: 'blur', message: ' ' },
          { pattern: /^[a-zA-Z0-9]*$/, trigger: 'blur', message: '只能输入数字或字母' }
        ],
        mac: [{ required: true, message: '请填写', trigger: 'blur' },
          { pattern: /^[a-fA-F0-9]+$/, message: '格式有误', trigger: 'blur' },
          { min: 2, max: 2, message: '2个字符', trigger: 'blur' }]
      },
      bindSn: '',
      brandModelOptions: [],
      showModelInfo: false,
      showBindDrawer: false,
      currentSn: ''
    }
  },
  methods: {
    async getDeviceBrandModelList () {
      const res = await getDeviceBrandModelList({ type: 3 })
      console.log('res:', res)
      if (res && res.code === 200) {
        this.brandModelOptions = res.data
      }
    },
    async getGatewayDetail (sn) {
      try {
        const res = await getGatewayDetail({ sn })
        this.form = res
        console.log('详情:', res)
        this.form = {
          mac0: '',
          mac1: '',
          mac2: '',
          mac3: '',
          mac4: '',
          mac5: '',
          gatewayType: res.data.gatewayType,
          modelCode: res.data.modelCode,
          deviceSn: res.data.deviceSn
        }
        this.tableData = res.data.bindDevices
        console.log(this.tableData)
        if (res.data.deviceAddr) {
          for (let i = 0; i < 6; i++) {
            this.form['mac' + i] = res.data.deviceAddr.slice(2 * i, 2 * i + 2)
          }
        }
      } catch (e) {
        this.$message({
          message: e.message,
          type: 'error'
        })
      }
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log('表单:', this.form)
          if (this.type === 'add') this.add()
          if (this.type === 'edit') this.edit()
        }
      })
    },
    async add () {
      const deviceAddr = `${this.form.mac0}${this.form.mac1}${this.form.mac2}${this.form.mac3}${this.form.mac4}${this.form.mac5}`.toLowerCase()
      try {
        const res = await addGateway({
          deviceAddr,
          deviceSn: this.form.deviceSn,
          modelCode: this.form.modelCode,
          gatewayType: this.form.gatewayType
        })
        if (res.code === 200) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.$confirm('保存成功，是否继续绑定ICM设备？')
            .then(() => {
              this.showBindModel()
              this.currentSn = this.form.deviceSn
            })
            .catch(() => {
              this.goBack()
            })
        } else {
          this.$message({
            message: res.message,
            type: 'error'
          })
        }
      } catch (err) {
        this.$message({
          message: err.message,
          type: 'error'
        })
      }
    },
    async edit () {
      const deviceAddr = `${this.form.mac0}${this.form.mac1}${this.form.mac2}${this.form.mac3}${this.form.mac4}${this.form.mac5}`.toLowerCase()
      try {
        const res = await editGateway({
          deviceAddr,
          deviceSn: this.form.deviceSn,
          modelCode: this.form.modelCode,
          gatewayType: this.form.gatewayType
        })
        if (res.code === 200) {
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.goBack()
        } else {
          this.$message({
            message: res.message,
            type: 'error'
          })
        }
      } catch (err) {
        this.$message({
          message: err.message,
          type: 'error'
        })
      }
    },
    async bindICM () {
      try {
        const res = await bindGateway({
          deviceSn: this.bindSn,
          gatewaySn: this.currentSn
        })
        if (res.code === 200) {
          this.$message({
            message: '绑定成功',
            type: 'success'
          })
          this.bindSn = ''
          if (this.type === 'add') {
            this.goBack()
            this.showBindDrawer = false
          } else {
            this.showBindDrawer = false
            await this.getGatewayDetail(this.form.deviceSn)
          }
        } else {
          this.$message({
            message: res.message,
            type: 'error'
          })
        }
      } catch (err) {
        this.$message({
          message: err.message,
          type: 'error'
        })
      }
    },
    unbindICM (row) {
      this.$confirm('设备解绑后无法恢复，确定解绑吗？')
        .then(async _ => {
          try {
            const res = await unbindGateway({
              deviceSn: row.deviceSn,
              gatewaySn: this.form.deviceSn
            })
            if (res.code === 200) {
              this.$message({
                message: '解绑成功',
                type: 'success'
              })
              await this.getGatewayDetail(this.form.deviceSn)
            }
          } catch (err) {
            this.$message({
              message: err.message,
              type: 'error'
            })
          }
        })
        .catch(_ => {})
      // unbindGateway
    },
    showAddModel () {
      this.showModelInfo = true
    },
    showBindModel () {
      this.showBindDrawer = true
    },
    async bindConfirm () {
      await this.bindICM()
    },
    bindCancel () {
      if (this.type === 'add') {
        this.goBack()
        this.showBindDrawer = false
      } else {
        this.showBindDrawer = false
        this.bindSn = ''
      }
    },
    showEditModel () {
      this.showEditDrawer = true
    },
    formatter (row, column) {
      return moment.unix(row.bindTime / 1000).format('Y-MM-DD HH:mm:ss')
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
.add-device {
  margin: 0 24px;
  .add-brand_btn {
    color: #409EFF;
    margin-left: 16px;
    cursor: pointer;
  }
}
.form-item__width {
  width: 320px;
}
.mac-box {
  display: inline-block;
  span  {
    margin: 0 10px;
  }
}
.mac-input {
  width: 100px;
}
</style>
