import { get } from '@/serve/axios'

// 1. 登陆
// export const login = (params) => post('admin/v1/passport/login', params)

// 2. 查询小程序记录
export const getList = (params) => get(`/admin/v1/icm/data/sample-record/list?pageNo=${params.pageNo}&pageSize=${params.pageSize}`)

// 3. 查询无双设备型号列表
export const getDeviceBrandModelList = (params) => get('admin/v1/commons/device-model/list', { params })

// 4. 查询所有医院列表
export const getHospitalList = () => get('admin/v1/commons/hospital/list')

// 用户列表
// 1. 分页查询注册用户列表
export const getUserList = (params) => get('admin/v1/user/list', { params })

// 网关列表
export const getBluetoothList = (params) => get('admin/v1/bluetooth/gateway-record/list', { params })

// 上传相关
// 1. 获取oss访问sts账号
export const getOssToken = () => get('admin/v1/commons/ali-oss/security')

// 2. 获取oss访问sts账号(固件升级专用)
export const getOssStsToken = () => get('admin/v1/commons/ali-oss/sts-token')

// 查询未录入设备信息
export const getPendDeviceInfo = (params) => get('admin/v1/icm/device-receipt/pending/device', { params })

// 钉钉登录
export const DDLogin = (authCode) => get(`admin/v1/passport/auth?authCode=${authCode}`)

// 设备类型查询
export const getDeviceTypeList = () => get('admin/v1/commons/device-type/list')

// 获取员工信息
export const getStuffInfo = () => get('admin/v1/account/profile')
