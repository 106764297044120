import { get, post } from '@/serve/axios'

// 员工管理
// 1. 获取员工列表
export const getStaffList = (params) => get('admin/v1/staff/list', { params })

// 2. 通过手机号获取钉钉员工信息
export const getStaffInfo = (params) => get('admin/v1/staff/dingTalk/detail', { params })

// 3. 添加新员工
export const addStuff = (params) => post('admin/v1/staff/add', params)

// 4. 启用禁用员工
export const changeStuffStatus = (params) => post(`admin/v1/staff/${params.status}/${params.id}`)
