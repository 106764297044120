<template>
  <div class="content">
    <header class="header">
      <part-title title="回执单管理"></part-title>
      <div class="search">
        <template>
          <span class="el-span">设备序列号：</span>
          <el-input size="small" class="el-input" v-model="searchData.deviceSn" placeholder="请输入设备序列号"></el-input>
          <el-button
            size="small"
            @click="onSearch"
            class="search-btn">
            查询
          </el-button>
        </template>
        <el-button type="primary" size="small" class="receipt-btn" @click="enterReceipt">
        <i class="el-icon-plus"></i>
        录入回执单
      </el-button>
      </div>
    </header>
    <el-table
    :data="tableData">
      <el-table-column
        align="center"
        fixed
        label="序号"
        type="index"
        width="50">
      </el-table-column>
      <el-table-column
        align="center"
        prop="patientName"
        label="患者姓名">
          <template slot-scope="scope">
            <span>{{scope.row.patientName}}</span>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="deviceSn"
        label="设备序列号">
          <template slot-scope="scope">
            <span>{{scope.row.deviceSn}}</span>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="brandType"
        label="品牌类型">
          <template slot-scope="scope">
           <div v-if="scope.row.brandName">
            {{scope.row.brandName}}
           </div>
           <div v-else>-</div>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="brandModel"
        label="品牌型号">
          <template slot-scope="scope">
           <div v-if="scope.row.modelName">
            {{scope.row.modelName}}
           </div>
           <div v-else>-</div>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="hospital"
        label="植入医院"
        min-width="170">
          <template slot-scope="scope">
           <div v-if="scope.row.hospitalName">
            {{scope.row.hospitalName}}
           </div>
           <div v-else>-</div>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="implantDate"
        label="植入日期">
          <template slot-scope="scope">
            <span v-if="scope.row.implantDate">{{moment.unix(scope.row.implantDate / 1000).format('Y-MM-DD')}}</span>
            <span v-else>-</span>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="operator"
        label="创建人">
        <template slot-scope="scope">
            <span v-if="scope.row.creatorName">{{scope.row.creatorName}}</span>
            <span v-else>-</span>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="createAt"
        label="创建时间"
        min-width="110">
          <template slot-scope="scope">
            <span v-if="scope.row.createAt">{{moment.unix(scope.row.createAt / 1000).format('Y-MM-DD HH:mm:ss')}}</span>
            <span v-else>-</span>
          </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="100">
        <template slot-scope="scope">
          <el-button class="detail-btn" @click="onClickDetails(scope.row)" type="text">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 80px"></div>
    <div class="paginationClass" v-if="tableData.length > 0">
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="searchData.pageNo"
      :page-size="searchData.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalPage">
      </el-pagination>
    </div>
    <EnterReceipt
      :visible.sync="enterReceiptDrawer"
      :userInfo="userInfo"
      @enterReceiptSuccess1 = enterReceiptSuccess1
    ></EnterReceipt>
  </div>
</template>

<script>
import { getDeviceReceiptList } from '@/serve/module/receipt'
import { symptomOpts } from '@/utils/enum'
import PartTitle from '@/components/Part'
import EnterReceipt from './enterReceipt.vue'
const moment = require('moment')
export default {
  components: {
    PartTitle,
    EnterReceipt
  },
  data () {
    return {
      moment: moment,
      symptomOpts,
      searchData: { // 搜索数据
        pageNo: 1, // 当前页
        pageSize: 20 // 每页条数
      },

      totalPage: 1,
      tableData: [],

      enterReceiptDrawer: false,

      userInfo: {},
      searchOptions: {}

    }
  },
  created () {
    this.getDeviceReceiptList(this.searchData)
  },
  methods: {
    handleSizeChange (val) {
      this.searchData.pageSize = val
      this.getDeviceReceiptList(this.searchData)
    },
    handleCurrentChange (val) {
      this.searchData.pageNo = val
      this.getDeviceReceiptList(this.searchData)
    },
    getDeviceReceiptList (params) {
      getDeviceReceiptList(params).then(res => {
        if (res) {
          this.tableData = res.data.data
          this.totalPage = res.data.total
        }
      })
    },
    onSearch () {
      this.getDeviceReceiptList(this.searchData)
    },
    onClickDetails (row) {
      this.$router.push({
        name: 'receiptDetails',
        query: {
          receiptId: row.id
        },
        params: row
      })
    },
    enterReceipt (row) {
      this.enterReceiptDrawer = true
      this.userInfo = row
    },
    enterReceiptSuccess1 () {
      this.searchData.pageSize = 20
      this.searchData.pageNo = 1
      this.getDeviceReceiptList(this.searchData)
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin: 0 24px;
}
.form {
  width: 100%;
}
.el-input {
  display: inline-block;
  width: 192px;
  margin-right: 24px;
}
.search {
  color: #606266;
}
.paginationClass {
background: #fff;
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 210px;
    z-index: 100;
    padding: 24px 20px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
::v-deep .el-table th.el-table__cell>.cell {
  color: #909399;
  font-weight: 400;
  font-size: 14px;
 }
.receipt-btn {
  float: right;
  margin-bottom: 10px;
}
.detail-btn {
  font-size: 14px;
  font-weight: 400;
}
</style>
