// 症状映射列表
export const symptomOpts = [
  { code: 1, text: '头晕', type: 1 },
  { code: 2, text: '心慌、气短', type: 1 },
  { code: 3, text: '心跳快', type: 1 },
  { code: 4, text: '胸痛', type: 1 },
  { code: 5, text: '昏厥', type: 1 },
  { code: 6, text: '其他', type: 1 },
  { code: 7, text: '无任何症状', type: 1 }
]
