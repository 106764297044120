<template>
  <el-drawer
    title="编辑产品型号信息"
    :visible="visible"
    :wrapperClosable="false"
    @close="onCancel"
    :close-on-press-escape="false"
    size="400px">
    <div style="margin: 24px">
      <el-form
        :model="ruleEditModelForm"
        :rules="rules"
        ref="ruleEditModelForm"
        label-width="86px"
        label-position="left"
        size="small"
        hide-required-asterisk>
        <el-form-item label="设备品牌">
          <el-input placeholder="无双医疗" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="设备类型" prop="typeCode">
          <el-select class="el-select" size="small" v-model="ruleEditModelForm.typeCode" placeholder="请选择">
            <el-option
              v-for="item in modelTypeOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
<!--        <el-form-item label="型号码" prop="code">-->
<!--          <el-input v-model="ruleEditModelForm.code"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="设备型号" prop="name">
          <el-input v-model="ruleEditModelForm.name" placeholder="请输入设备型号"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="drawer-footer">
      <div class="footer-box">
        <el-button class="footer-btn" @click="onCancel" size="small">取消</el-button>
        <el-button class="footer-submit-btn" type="primary" size="small" @click="editModel">保存</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { getModelDeviceDetail, editModelDevice } from '@/serve/module/modelManage'
import { getDeviceTypeList } from '@/serve/module/common'
export default {
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    modelId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      ruleEditModelForm: {},
      formData: {},
      rules: {
        typeCode: [
          { required: true, message: '请选择设备类型', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入设备型号', trigger: 'blur' }
        ]
      },
      modelTypeOptions: []
    }
  },
  watch: {
    visible: {
      handler (newVal) {
        console.log('visible:', newVal)
        if (newVal) {
          this.getModelDeviceDetail(this.modelId)
          this.getDeviceTypeList()
        }
      }
    }
  },
  methods: {
    getDeviceTypeList () {
      getDeviceTypeList().then(res => {
        if (res.data) {
          this.modelTypeOptions = res.data
        }
      })
    },
    onCancel () {
      this.$emit('update:visible', false)
    },
    editModelDevice (params) {
      editModelDevice(params).then(res => {
        if (res.code === 200) {
          this.$message.success('修改成功')
          this.$emit('update:visible', false)
          this.$emit('editModel')
        } else {
          this.$message.error(res.message)
        }
      }).catch((err) => {
        this.$message.error(err)
      })
    },
    getModelDeviceDetail (id) {
      getModelDeviceDetail(id).then(res => {
        if (res.code === 200) {
          this.ruleEditModelForm = res.data
        } else {
          this.$message.error(res.message)
        }
      }).catch((err) => {
        this.$message.error(err)
      })
    },
    editModel () {
      this.$refs.ruleEditModelForm.validate((valid) => {
        if (valid) {
          this.formData.id = this.modelId
          this.formData.type = this.ruleEditModelForm.typeCode
          this.formData.name = this.ruleEditModelForm.name
          this.editModelDevice(this.formData)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-drawer__header {
  font-weight: 500;
  font-size: 16px;
  color: #0F1114;
  padding: 16px 0px 16px 24px;
  margin-bottom: 0;
  box-shadow: inset 0px -1px 0px #E7E8EA;
}

.drawer-footer {
  width: 100%;
  height: 64px;
  box-shadow: inset 0px 1px 0px #E7E8EA;
  position: absolute;
  bottom: 0px;
  .footer-box {
    float: right;
    .footer-btn {
    margin: 16px 12px 16px 12px;
  }
    .footer-submit-btn {
      margin: 16px 24px 16px 0;
    }
  }
}

.el-select {
  width: 100%;
}

::v-deep .el-form-item__label::after {
    content: '*';
    color: #F56C6C;
    margin-left: 4px;
}
</style>
