<template>
  <el-drawer
    title="查看固件信息"
    :visible.sync="visible"
    :wrapperClosable="false"
    :close-on-press-escape="false"
    @close="onCancel"
    size="400px">
    <div style="margin: 24px">
      <div>
        <span class="title">应用：</span>
        <span class="content">{{tableData.deviceType === 0 ? 'HFM':'ICM'}}</span>
      </div>
      <div>
        <span class="title">版本号：</span>
        <span class="content">{{ tableData.version }}</span>
      </div>
      <div>
        <span class="title">更新内容：</span>
        <pre class="content">{{ txt }}</pre>
      </div>
      <div>
        <span class="title">升级包大小：</span>
        <span class="content">{{ tableData.size }}</span>
      </div>
      <div>
        <span class="title">是否限制升级：</span>
        <span class="content">{{ tableData.force === 1 ? '是': '否' }}</span>
      </div>
    </div>
    <div class="drawer-footer">
      <div class="footer-box">
        <el-button class="footer-btn" @click="onCancel" size="small">返回</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { getFeedBackDetails } from '@/serve/module/firmwareUpdateManage'
const moment = require('moment')
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    firmwareInfo: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      moment: moment,
      tableData: {},
      txt: ''
    }
  },
  watch: {
    visible: {
      handler (newVal) {
        if (newVal) {
          getFeedBackDetails({
            firmwareId: this.firmwareInfo.id
          }).then(res => {
            if (res) {
              this.tableData = res.data
              this.txt = res.data.description.replace('/\n/g', '<br>')
            }
          })
        }
      }
    }
  },
  methods: {
    onCancel () {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-drawer__header {
  font-weight: 500;
  font-size: 16px;
  color: #0F1114;
  padding: 16px 0px 16px 24px;
  margin-bottom: 0;
  box-shadow: inset 0px -1px 0px #E7E8EA;
}

.drawer-footer {
  width: 100%;
  height: 64px;
  box-shadow: inset 0px 1px 0px #E7E8EA;
  position: absolute;
  bottom: 0px;
  .footer-box {
    float: right;
    .footer-btn {
    margin: 16px 12px 16px 12px;
  }
    .footer-submit-btn {
      margin: 16px 24px 16px 0;
    }
  }
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
}
</style>
