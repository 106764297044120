<template>
  <div style="margin: 0 24px;">
    <header class="header">
      <part-title title="无双产品型号管理"></part-title>
      <el-button type="primary" size="small" class="receipt-btn" @click="onAddModel">
        <i class="el-icon-plus"></i>
        添加型号
      </el-button>
    </header>
    <el-table
    :data="tableData">
      <el-table-column
        align="center"
        fixed
        label="序号"
        type="index"
        width="50">
      </el-table-column>
      <el-table-column
        align="center"
        prop="deviceSn"
        label="型号码">
          <template slot-scope="scope">
            <div v-if="scope.row.code">
            {{scope.row.code}}
           </div>
           <div v-else>-</div>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="name"
        label="设备型号">
          <template slot-scope="scope">
           <div v-if="scope.row.name">
            {{scope.row.name}}
           </div>
           <div v-else>-</div>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="type"
        label="设备类型">
        <template slot-scope="scope">
            <span v-if="scope.row.type">{{scope.row.type}}</span>
            <span v-else>-</span>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="brandName"
        label="设备品牌">
        <template slot-scope="scope">
            <span v-if="scope.row.brandName">{{scope.row.brandName}}</span>
            <span v-else>-</span>
          </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="createAt"
        label="添加时间">
          <template slot-scope="scope">
            <span v-if="scope.row.createAt">{{moment.unix(scope.row.createAt / 1000).format('Y-MM-DD HH:mm:ss')}}</span>
            <span v-else>-</span>
          </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="100">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 80px"></div>
    <div class="paginationClass" v-if="tableData.length > 0">
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="searchData.pageNo"
      :page-size="searchData.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalPage">
      </el-pagination>
    </div>
    <AddModel
    :visible.sync="isVisibleAddModel"
    @addModel="handelAddModel"
    />
    <EditModel
    :visible.sync="isVisibleEditModel"
    :modelId="modelId"
    @editModel="handelEditModel"
    />
  </div>
</template>

<script>
import { getModelList } from '@/serve/module/modelManage'
import { symptomOpts } from '@/utils/enum'
import PartTitle from '@/components/Part'
import AddModel from '../addModelInfo'
import EditModel from '../editModelInfo'
const moment = require('moment')
export default {
  components: {
    PartTitle,
    AddModel,
    EditModel
  },
  data () {
    return {
      moment: moment,
      symptomOpts,
      searchData: { // 搜索数据
        pageNo: 1, // 当前页
        pageSize: 20 // 每页条数
      },

      totalPage: 1,
      tableData: [],

      drawer: false,

      isVisibleAddModel: false,
      isVisibleEditModel: false,
      isEditDrawer: true,

      modelId: null

    }
  },
  created () {
    this.getModelList(this.searchData)
  },
  filters: {
    // 当标题字数超出时，超出部分显示’...‘。此处限制超出8位即触发隐藏效果
    ellipsis (value) {
      if (!value) return ''
      if (value.length > 8) {
        return value.slice(0, 8) + '...'
      }
      return value
    }
  },
  methods: {
    handleSizeChange (val) {
      this.searchData.pageSize = val
      this.getModelList(this.searchData)
    },
    handleCurrentChange (val) {
      this.searchData.pageNo = val
      this.getModelList(this.searchData)
    },
    getModelList (params) {
      getModelList(params).then(res => {
        if (res) {
          this.tableData = res.data.data
          this.totalPage = res.data.total
        }
      })
    },
    handleClick (e) {
      this.modelId = e.id
      this.isVisibleEditModel = true
    },
    onAddModel () {
      this.isVisibleAddModel = true
      this.isEditDrawer = false
    },
    handelAddModel () {
      this.getModelList(this.searchData)
    },
    handelEditModel () {
      this.getModelList(this.searchData)
    }
  }
}
</script>

<style scoped lang="scss">

.paginationClass {
background: #fff;
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 210px;
    z-index: 100;
    padding: 24px 20px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
::v-deep .el-table th.el-table__cell>.cell {
  color: #909399;
  font-weight: 400;
  font-size: 14px;
 }

 ::v-deep .el-button--text {
  font-size: 14px;
  font-weight: 400;
}
 .set-popper {
  width: 431px;
}
.set-content {
  cursor: default;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.top {
    height: 40px;
    width: 80px;
    position:absolute;
    top:10px;
    right:20px;
     z-index: 999;
     background-color: #f8faff;
     border-radius: 8px;
    .top-avatar {
      display: flex;
      align-items: center;
      .showList {
        cursor: pointer;
      }
      .top-avatar_img {
        margin-right: 10px;
      }
    }
  }
.receipt-btn {
  float: right;
  margin-bottom: 10px;
}
::v-deep .el-drawer__header {
  font-weight: 500;
  font-size: 16px;
  color: #0F1114;
  padding: 16px 0px 16px 24px;
  margin-bottom: 0;
  box-shadow: inset 0px -1px 0px #E7E8EA;
}

.drawer-footer {
  position: fixed;
  bottom: 0;
  height: 60px;
  right: 20px;
}
</style>
