<template>
  <div class="hospital-manage-content">
    <part-title title="ICM设备管理"></part-title>
<!--    <el-scrollbar style="height: 100%">-->
      <div class="search">
        <template>
          <span class="el-span">设备序列号：</span>
          <el-input
          size="small"
          class="el-input"
          v-model="searchData.deviceSn"
          placeholder="请输入设备序列号">
          </el-input>
          <el-button
            type="primary"
            size="small"
            @click="onSearch"
            class="search-btn">
            查询
          </el-button>
        </template>
      </div>
      <div class="add-new">
        <el-button size="small" type="primary" @click="addNew">添加设备</el-button>
      </div>
      <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        fixed
        align="center"
        type="index"
        label="序号">
      </el-table-column>
      <el-table-column
        align="center"
        label="设备序列号">
        <template slot-scope="scope">
          <span v-if="scope.row.deviceSn" >{{scope.row.deviceSn}}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
        <el-table-column
          align="center"
          label="蓝牙MAC地址"
          prop="deviceAddr">
        </el-table-column>
      <el-table-column
        align="center"
        label="品牌型号">
        <template slot-scope="scope">
          <span v-if="scope.row.brandName" >{{scope.row.brandName + scope.row.modelName}}</span>
          <span v-else>-</span>
          </template>
      </el-table-column>
<!--      <el-table-column-->
<!--        label="设备状态">-->
<!--        <template slot-scope="scope">-->
<!--          {{ scope.row.deviceStatus === 1 ?'已启用' : (scope.row.deviceStatus === 2 ? '电池RT': '已停用') }}-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--        label="植入日期"-->
<!--        width="200">-->
<!--        <template slot-scope="scope">-->
<!--            <span>{{moment.unix(scope.row.implantDate / 1000).format('Y-MM-DD')}}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--        label="植入医院">-->
<!--        <template slot-scope="scope">-->
<!--          <span v-if="scope.row.hospitalName" >{{scope.row.hospitalName}}</span>-->
<!--          <span v-else>-</span>-->
<!--          </template>-->
<!--      </el-table-column>-->
      <el-table-column
        align="center"
        label="创建人"
        width="200"
        prop="creator">
      </el-table-column>
      <el-table-column
        align="center"
        label="创建时间"
        width="200">
        <template slot-scope="scope">
            <span>{{moment.unix(scope.row.createAt / 1000).format('Y-MM-DD HH:mm:ss')}}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        fixed="right"
        label="操作"
        width="120">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="onEdit(scope.row)">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 80px;"></div>
    <!-- 分页 -->
    <Page
        @handleSizeChange="sizeChange"
        @handleCurrentChange="pageChange"
        :current-page="searchData.pageNo"
        :page-size="searchData.pageSize"
        :total="totalPage"
    ></Page>
<!--    </el-scrollbar>-->
    <el-drawer
      title="编辑ICM设备信息"
      :visible.sync="isVisibleAddICMDeviceDrawer"
      :wrapperClosable="false"
      :close-on-press-escape="false"
      :before-close="dialogClear"
      size="460px">
      <div style="margin: 24px">
        <el-form
          :model="ruleICMDeviceForm"
          ref="ruleICMDeviceForm"
          label-width="110px"
          label-position="left"
          size="small">
          <el-form-item label="品牌型号" prop="modelCode" :rules="rules.modelCode">
          <div style="width: 100%;">
            <el-select
              v-model="ruleICMDeviceForm.modelCode"
              style="width: 100%"
              placeholder="请选择">
              <el-option
                v-for="item in brandModelOptions"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="蓝牙mac地址" :required="true">
          <el-form-item class="mac-box" v-for="i in 6" :key="i" :prop="'mac' + (i - 1)" :rules="rules.mac">
            <el-input maxlength="2" class="mac-input" v-model="ruleICMDeviceForm['mac' + (i - 1)]" size="small"></el-input>
            <span v-if="i !== 6">:</span>
          </el-form-item>
        </el-form-item>
        </el-form>
        <div class="line"></div>
        <div class="drawer-footer">
          <el-button class="footer-btn" @click="onCancel" size="small">取消</el-button>
          <el-button class="footer-btn" type="primary" @click="handelEdit" size="small">保存</el-button>
        </div>
      </div>
    </el-drawer>
    <AddModelInfo :visible.sync="isAddModelInfo" @addModelInfo="addModelInfo">
    </AddModelInfo>
  </div>
</template>
<script>
import PartTitle from '@/components/Part'
import Page from '@/components/Page'
import { getICMDeviceList, editICMDeviceInfo } from '@/serve/module/ICMDeviceManage'
import { getDeviceBrandModelList } from '@/serve/module/common'
import AddModelInfo from './addModelInfo.vue'
const moment = require('moment')
export default {
  components: {
    PartTitle,
    Page,
    AddModelInfo
  },
  data () {
    return {
      editFlag: true,
      moment: moment,
      rules: {
        modelCode: [
          { required: true, message: '请输入设备序列号', trigger: 'change' }
        ],
        deviceSn: [
          { required: true, message: '请输入设备序列号', trigger: 'blur' },
          { pattern: /^[A-Za-z\d]+$/, message: '格式有误，请输入英文/数字' }
        ],
        mac: [
          { required: true, message: '请填写', trigger: 'blur' },
          { pattern: /^[a-fA-F0-9]+$/, message: '格式有误', trigger: 'blur' },
          { min: 2, max: 2, message: '2个字符', trigger: 'blur' }
        ]
      },
      tableData: [],
      handleOpts: {
      },
      searchData: {
        pageNo: 1, // 当前条数
        pageSize: 20, // 每页条数,
        deviceType: 1,
        deviceSn: ''
      },
      totalPage: 0, // 总条数

      isVisibleAddICMDeviceDrawer: false,
      brandModelOptions: [ // ICM品牌型号列表
      ],
      isAddModelInfo: false,
      ruleICMDeviceForm: {},

      formDataBackup: {}
    }
  },
  mounted () {
    this.getICMDeviceList({ deviceType: 1 })
  },
  methods: {
    getDeviceBrandModelList () {
      getDeviceBrandModelList({ type: 1 }).then(res => {
        if (res) {
          this.brandModelOptions = res.data
        }
      })
    },
    getICMDeviceList (params) {
      getICMDeviceList(params).then(res => {
        if (res && res.code === 200) {
          this.tableData = res.data.data
          this.totalPage = res.data.total
          this.pageNo = res.data.pageNo
          this.pageSize = res.data.pageSize
        } else {
          this.$message.error(res.message)
        }
      })
    },
    addNew () {
      this.$router.push({
        path: '/deviceManage/addDevice'
      })
    },
    sizeChange (val) {
      this.searchData.pageSize = val
      this.getICMDeviceList(this.searchData)
    },
    pageChange (val) {
      this.searchData.pageNo = val
      this.getICMDeviceList(this.searchData)
    },
    async onEdit (row) {
      await this.getDeviceBrandModelList()
      this.isVisibleAddICMDeviceDrawer = true
      this.ruleICMDeviceForm = {
        mac0: '',
        mac1: '',
        mac2: '',
        mac3: '',
        mac4: '',
        mac5: '',
        modelCode: row.modelCode,
        deviceSn: row.deviceSn
      }
      console.log(row.deviceAddr)
      for (let i = 0; i < 6; i++) {
        this.ruleICMDeviceForm['mac' + i] = row.deviceAddr.slice(2 * i, 2 * i + 2)
      }
    },
    dialogClear () {
      this.isVisibleAddICMDeviceDrawer = false
      this.$refs.ruleICMDeviceForm.resetFields()
    },
    onSearch () {
      this.getICMDeviceList(this.searchData)
    },
    onCancel () {
      this.isVisibleAddICMDeviceDrawer = false
      this.$refs.ruleICMDeviceForm.resetFields()
    },
    handelEdit () {
      this.$refs.ruleICMDeviceForm.validate(async (valid) => {
        if (valid) {
          if (!this.editFlag) {
            return false
          }
          try {
            this.editFlag = false
            console.log(this.ruleICMDeviceForm)
            const deviceAddr = `${this.ruleICMDeviceForm.mac0}${this.ruleICMDeviceForm.mac1}${this.ruleICMDeviceForm.mac2}${this.ruleICMDeviceForm.mac3}${this.ruleICMDeviceForm.mac4}${this.ruleICMDeviceForm.mac5}`.toLowerCase()
            const res = await editICMDeviceInfo({
              deviceAddr,
              modelCode: this.ruleICMDeviceForm.modelCode,
              deviceSn: this.ruleICMDeviceForm.deviceSn
            })
            this.editFlag = true
            if (res.code === 200) {
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              this.getICMDeviceList(this.searchData)
              this.isVisibleAddICMDeviceDrawer = false
            } else {
              this.$message({
                message: res.message,
                type: 'error'
              })
            }
          } catch (e) {
            this.editFlag = true
            this.$message({
              message: e.message,
              type: 'error'
            })
          }
          // editICMDeviceInfo(this.ruleICMDeviceForm).then(res => {
          //   if (res.code === 200) {
          //     this.$message.success('保存成功')
          //     this.isVisibleAddICMDeviceDrawer = false
          //     this.getICMDeviceList()
          //   } else {
          //     this.$message.error(res.message)
          //   }
          // }).catch(() => {
          //   this.$message.error('网络错误')
          // })
        }
      })
    },
    onAddModelInfo () {
      this.isAddModelInfo = true
    },
    addModelInfo () {
      this.getDeviceBrandModelList()
    },
    onChangeDeviceSn (e) {
      this.$forceUpdate()
      this.$refs.ruleICMDeviceForm.validate((valid) => {
        if (valid) {
          this.ruleICMDeviceForm.deviceSn = e
        }
      })
    },
    onChangeModelCode (e) {
      this.$forceUpdate()
      this.$refs.ruleICMDeviceForm.validate((valid) => {
        if (valid) {
          this.ruleICMDeviceForm.modelCode = e
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">

.search {
    margin-bottom: 12px;
    height: 32px;
    line-height: 32px;
    .el-input,.el-select {
      width: 150px;
      margin-right: 24px;
    }
    .el-span {
      font-weight: 400;
      font-size: 14px;
    }
    // .search-btn {
    //   font-size: 14px;
    // }
  }
.add-new {
  margin-bottom: 12px;
}
.hospital-manage-content {
  margin: 0 24px;
  .content-btn {
    float: right;
    margin-bottom: 12px;
    font-size: 14px;
  }
}
::v-deep .el-drawer__header {
  font-weight: 500;
  font-size: 16px;
  color: #0F1114;
  padding: 16px 0px 16px 24px;
  margin-bottom: 0;
  box-shadow: inset 0px -1px 0px #E7E8EA;
}
.drawer-footer {
  position: fixed;
  bottom: 0;
  height: 60px;
  right: 20px;
}
::v-deep .el-button--text {
  font-size: 14px;
  font-weight: 400;
}
.page,.page-shadow {
  background: #fff;
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 210px;
  z-index: 100;
  padding: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.line {
  height: 1px;
  width: 400px;
  position: fixed;
  bottom: 64px;
  right: 0;
  box-shadow: inset 0px 1px 0px #E7E8EA;
}
.drawer-footer {
  position: fixed;
  bottom: 0;
  height: 48px;
  right: 20px;
}
.mac-box {
  display: inline-block;
  width: 50px;
  span  {
    margin: 0 5px;
  }
  ::v-deep .el-input__inner {
    padding: 0 5px;
  }
}
.mac-input {
  width: 30px;
}
</style>
