import { get, post } from '@/serve/axios'

// 设备管理-回执单
// 1. 分页查询回执单列表
export const getDeviceReceiptList = (params) => get('admin/v1/icm/device-receipt/list', { params })

// 2. 创建回执单
export const createDeviceReceipt = (params) => post('admin/v1/icm/device-receipt/add', params)

// 3. 编辑回执单
export const editDeviceReceipt = (params) => post('admin/v1/icm/device-receipt/edit', params)

// 4. 查询回执单详情
export const getDeviceReceiptDetail = (receiptId) => get(`admin/v1/icm/device-receipt/detail?receiptId=${receiptId}`)

// 5. 回执单修改记录列表
export const getModifyList = (receiptId) => get(`admin/v1/icm/device-receipt/modify/log?receiptId=${receiptId}`)

// 6. 姓名与手机号联想
export const searchPatientSimpleInfo = (patientName) => get(`admin/v1/patient/simple-info/search?patientName=${patientName}`)
