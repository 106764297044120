import { get, post } from '@/serve/axios'

// 固件升级
// 1. 查询固件列表
export const getFeedBackList = (params) => get('admin/v1/device/firmware/list', { params })

// 2. 新增固件
export const addFeedBack = (params) => post('admin/v1/device/firmware/add', params)

// 3. 查询固件详情
export const getFeedBackDetails = (params) => get('admin/v1/device/firmware/detail', { params })

// 4. 发布固件
export const releaseFeedBack = (params) => post(`admin/v1/device/firmware/release?firmwareId=${params.firmwareId}`)

// 5. 编辑固件
export const editFeedBack = (params) => post('admin/v1/device/firmware/edit', params)
