import { get, post } from '@/serve/axios'

// 医院管理
// 1. 医院列表
export const getHospitalList = (params) => get(`admin/v1/hospital/list?pageNo=${params.pageNo}&pageSize=${params.pageSize}`)

// 2. 添加医院
export const addHospital = (params) => post('admin/v1/hospital/add', params)

// 3. 修改医院信息
export const editHospital = (params) => post('admin/v1/hospital/edit', params)

// 4. 获取医院信息
export const getHospitalInfo = (id) => get(`admin/v1/hospital/detail?hospitalId=${id}`)

// 5. 医院禁用启用
export const editHospitalStatus = (params) => post(`admin/v1/hospital/${params.status}/${params.id}`)

// 6. 获取医院二维码
export const getInviteQrcode = (id) => get(`admin/v1/hospital/inviteQrcode/${id}`, { responseType: 'blob' })

// 医生管理
// 1. 获取医生列表
export const getDoctorList = (params) => get(`admin/v1/hospital/doctor/list?hospitalId=${params.hospitalId}&pageNo=${params.pageNo}&pageSize=${params.pageSize}`)

// 2. 启用禁用医生
export const changeDoctorAccount = (params) => post(`admin/v1/hospital/doctor/${params.status}/${params.id}`)

// 3. 获取医生信息
export const getDoctorInfo = (id) => get(`admin/v1/hospital/doctor/detail?doctorId=${id}`)

// 4. 新建医生
export const addDoctor = (params) => post('admin/v1/hospital/doctor/add', params)

// 5. 编辑医生信息
export const editDoctor = (params) => post('admin/v1/hospital/doctor/edit', params)
