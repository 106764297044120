<template>
  <div>
    <el-drawer
    title="编辑患者基本信息"
    :visible="visible"
    direction="rtl"
    :wrapperClosable="false"
    :close-on-press-escape="false"
    @close="handleClose"
    size="400px">
    <el-form label-position="left" ref="formEditUserDetails" :rules="rules" :model="formData" label-width="40px" class="formEditUserDetails" size="small">
      <el-form-item label="地区">
        <el-cascader
          v-model="formData.formAddress"
          :placeholder="defaultAddress"
          :options="addressOptions"
          style="width: 100%;"
          filterable/>
      </el-form-item>
      <el-form-item label="住址">
        <el-input v-model="formData.addressDetail" placeholder="请输入住址" maxlength="100" @input="onInputAddressDetail"></el-input>
      </el-form-item>
    </el-form>

    <div class="line"></div>
      <div class="drawer-footer">
        <el-button class="footer-btn" @click="handleClose" size="small">取消</el-button>
        <el-button class="footer-btn" type="primary" @click="onSubmit" size="small">保存</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import options from '@/utils/region'
import { editPatientInfo } from '@/serve/module/userManage'
export default {
  components: {
  },
  data () {
    const checkpatientPhone = (rule, value, callback) => {
      const reg = /^1[0-9]{10}$/
      if (this.isChangePhone && value && reg.test(value) === false) {
        callback(new Error('请输入正确的联系手机号'))
        return false
      } else {
        callback()
        return true
      }
    }
    return {
      formData: {},
      pickerOptions: {
        // 选择今天以及今天以前的日期
        disabledDate (time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },
      addressOptions: options, // 居住地地址列表
      drawer: false,
      defaultAddress: '请选择', // 常住地

      rules: {
        icmReservedPhone: [
          { validator: checkpatientPhone, trigger: 'blur' }
        ]
      },
      isChangePhone: false
    }
  },
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    tableData: {
      type: Object
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.formData.addressDetail = this.tableData.addressDetail
        this.formData.icmPhone = this.tableData.icmPhone
        this.formData.icmReservedPhone = this.tableData.icmReservedPhone
        this.formData.formAddress = []
        this.formData.id = this.tableData.id
        this.formData.formAddress[0] = this.tableData.province
        this.formData.formAddress[1] = this.tableData.city
        this.formData.formAddress[2] = this.tableData.district
      }
    }
  },
  methods: {
    onSubmit () {
      this.$refs.formEditUserDetails.validate((valid) => {
        if (valid) {
          this.formData.province = this.formData.formAddress[0]
          this.formData.city = this.formData.formAddress[1]
          this.formData.district = this.formData.formAddress[2]

          editPatientInfo(this.formData).then(res => {
            if (res.code === 200) {
              this.$message.success('保存成功')
              this.handleClose()
              this.$emit('editPatientInfo')
            } else {
              this.$message.error(res.message)
            }
          }).catch(() => {
            this.$message.error('网络错误')
          })
        }
      })
    },
    handleClose () {
      this.$emit('update:visible', false)
    },
    onInputAddressDetail () {
      this.$forceUpdate()
    },
    onInputPhone () {
      this.$forceUpdate()
      this.isChangePhone = true
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-drawer__header {
  font-weight: 500;
  font-size: 16px;
  color: #0F1114;
  padding: 16px 0px 16px 24px;
  margin-bottom: 0;
  box-shadow: inset 0px -1px 0px #E7E8EA;
}
.formEditUserDetails {
  margin: 24px;
  .submit-btn {
    width: 100%;
  }
}
::v-deep .el-drawer__header {
  font-weight: 500;
  font-size: 16px;
  color: #0F1114;
}
.el-select {
  width: 100%;

}
.line {
  height: 1px;
  width: 400px;
  position: fixed;
  bottom: 64px;
  right: 0;
  box-shadow: inset 0px 1px 0px #E7E8EA;
}
.drawer-footer {
  position: fixed;
  bottom: 0;
  height: 48px;
  right: 20px;
}
</style>
