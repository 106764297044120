<template>
  <div class="userDetails-box">
    <div class="box-title">
      <span class="title-one">用户管理 / </span>
      <span class="title-two">家属详情</span>
    </div>
    <div class="box-userBasicInfo">
      <p class="title">
        家属基本信息
        <el-button
          v-if="this.$route.query.patientId"
          type="text"
          @click="onEditUserInfo"
          class="edit-btn">
          编辑
          <i class="el-icon-edit-outline"></i>
        </el-button>
        <el-button
          v-if="isMore"
          type="text"
          @click="onAll"
          class="edit-btn">
          查看全部
          <i class="el-icon-edit-outline"></i>
        </el-button>
      </p>
      <el-descriptions :column="3">
        <el-descriptions-item label="姓名">
          <span v-if="tableData.name">
            {{tableData.name}}
          </span>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="地区">
          <span v-if="tableData.province && tableData.city && tableData.district">
            {{tableData.province + tableData.city + tableData.district}}
          </span>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="注册来源">
          <span v-if="tableData.appName">
            {{tableData.appName}}
          </span>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="性别">
          <span v-if="tableData.gender">{{tableData.gender === 1 ? '男':'女'}}</span>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="住址">
          <span v-if="tableData.addressDetail">
            {{tableData.addressDetail}}
          </span>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="随访手机号">
          <span v-if="tableData.followUpPhone">
            {{tableData.followUpPhone}}
            <el-button v-if="tableData.followUpPhone.indexOf('*') !== -1" type="text" class="el-icon-view" @click="onPlaintext('userFollowUpPhone')"></el-button>
          </span>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="年龄">
          <span v-if="tableData.age">{{tableData.age}}</span>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="身份证">
          <span v-if="tableData.idNumber">
            {{tableData.idNumber}}
            <el-button v-if="tableData.idNumber.indexOf('*') !== -1" type="text" class="el-icon-view" @click="onPlaintext('patientIdNumber')"></el-button>
          </span>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="HFM手机号">
          <span v-if="tableData.hfmPhone">
            {{tableData.hfmPhone}}
            <el-button v-if="tableData.hfmPhone.indexOf('*') !== -1" type="text" class="el-icon-view" @click="onPlaintext('userHfmPhone')"></el-button>
          </span>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="ICM手机号">
          <span v-if="tableData.userIcmPhone">
            {{tableData.userIcmPhone}}
            <el-button v-if="tableData.userIcmPhone.indexOf('*')!== -1" type="text" class="el-icon-view" @click="onPlaintext('userIcmPhone')"></el-button>
          </span>
          <span v-else>-</span>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div v-if="this.$route.query.userId" class="box-patientInfo">
      <p class="title">
        家属的患者信息
      </p>
      <el-table
      :data="patientsList"
      :row-style="{height:'20px'}"
      :cell-style="{padding:'3px'}"
      :header-row-style="headerRowStyle">
        <el-table-column
          align="center"
          fixed
          label="序号"
          type="index"
          width="50">
        </el-table-column>
        <el-table-column
          align="center"
          label="患者姓名">
          <template slot-scope="scope">
            <el-button type="text" @click="onClickPatient(scope.row)">
              <span v-if="scope.row.name" >{{scope.row.name}}</span>
              <span v-else>-</span>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="etiology"
          label="性别"
          width="50">
          <template slot-scope="scope">
            <span v-if="scope.row.gender" >{{scope.row.gender === 1? '男':'女'}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="etiology"
          label="年龄"
          width="50">
          <template slot-scope="scope">
            <span v-if="scope.row.age" >{{scope.row.age}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="身份证号"
          width="200">
          <template slot-scope="scope">
              <span v-if="scope.row.idNumber">{{scope.row.idNumber}}</span>
              <span v-else>-</span>
            </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="地区"
          width="70">
          <span v-if="tableData.province && tableData.city && tableData.district">
            {{tableData.province + tableData.city + tableData.district}}
          </span>
          <span v-else>-</span>
        </el-table-column>
        <el-table-column
          align="center"
          label="住址"
          width="150px">
          <span v-if="tableData.addressDetail">
            {{tableData.addressDetail}}
          </span>
          <span v-else>-</span>
        </el-table-column>
        <el-table-column
          align="center"
          prop="phone"
          label="ICM术后预留手机号"
          width="150px">
          <template slot-scope="scope">
            <span v-if="scope.row.icmReservedPhone" >{{scope.row.icmReservedPhone}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="绑定状态">
          <template>
            <span>绑定中</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
<!--    <div v-if="this.$route.query.patientId" class="box-familyInfo">-->
<!--      <p class="title">-->
<!--        关注该用户的家属信息-->
<!--      </p>-->
<!--      <el-table-->
<!--      :data="usersList">-->
<!--        <el-table-column-->
<!--          align="center"-->
<!--          fixed-->
<!--          label="序号"-->
<!--          type="index"-->
<!--          width="50">-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--          align="center"-->
<!--          label="家属姓名">-->
<!--         <template slot-scope="scope">-->
<!--            <el-button type="text" @click="onClickPatient1(scope.row)">-->
<!--              <span v-if="scope.row.name" >{{scope.row.name}}</span>-->
<!--              <span v-else>-</span>-->
<!--            </el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--          align="center"-->
<!--          prop="etiology"-->
<!--          label="性别">-->
<!--          <template slot-scope="scope">-->
<!--            <span v-if="scope.row.gender" >{{scope.row.gender === 1? '男':'女'}}</span>-->
<!--            <span v-else>-</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--          align="center"-->
<!--          prop="etiology"-->
<!--          label="年龄">-->
<!--          <template slot-scope="scope">-->
<!--            <span v-if="scope.row.age" >{{scope.row.age}}</span>-->
<!--            <span v-else>-</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--          align="center"-->
<!--          label="我是患者的">-->
<!--          <template slot-scope="scope">-->
<!--            <span v-if="scope.row.relationType" >{{scope.row.relationType}}</span>-->
<!--            <span v-else>-</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--          align="center"-->
<!--          prop="etiology"-->
<!--          label="注册来源">-->
<!--          <template slot-scope="scope">-->
<!--            <span v-if="scope.row.appName"> {{ scope.row.appName }}</span>-->
<!--            <span v-else>-</span>-->
<!--        </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--          align="center"-->
<!--          label="随访手机号">-->
<!--          <template slot-scope="scope">-->
<!--            <span v-if="scope.row.followUpPhone" >{{scope.row.followUpPhone}}</span>-->
<!--            <span v-else>-</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--          align="center"-->
<!--          label="HFM手机号">-->
<!--          <template slot-scope="scope">-->
<!--            <span v-if="scope.row.hfmPhone" >{{scope.row.hfmPhone}}</span>-->
<!--            <span v-else>-</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--          align="center"-->
<!--          label="ICM手机号">-->
<!--          <template slot-scope="scope">-->
<!--            <span v-if="scope.row.icmPhone" >{{scope.row.icmPhone}}</span>-->
<!--            <span v-else>-</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--      </el-table>-->
<!--    </div>-->
    <EditUserDetails
    :visible.sync="isShowEdit"
    :tableData="tableData"
    @editPatientInfo="editPatientInfo"
    />
    <IDAuth
    :visible.sync="isShowIDAuth"
    :tableData="tableData"
    :dataName="dataName"
    @sensitiveData="sensitiveData"/>
    <AllInfo
    :visible.sync="isShowAllInfo"
    :userBasicInfo="userBasicInfo"
    />
  </div>
</template>
<script>
import EditUserDetails from './editUserDetails.vue'
import { getPatientInfo, getUserInfo, getUserSensitiveInfo, getPatientSensitiveInfo, getUserDetail, getPatientDetail } from '@/serve/module/userManage'
// import PartTitle from '@/components/Part'
import IDAuth from './IDAuth.vue'
import AllInfo from './allInfo.vue'
const moment = require('moment')
export default {
  components: {
    // PartTitle
    EditUserDetails,
    IDAuth,
    AllInfo
  },
  data () {
    return {
      moment: moment,

      userInfo: {},
      tableData: {},
      usersList: [],
      patientsList: [],

      isShowEdit: false,
      isShowIDAuth: false,
      isShowAllInfo: false,

      dataName: '',
      isMore: '',
      userBasicInfo: []

    }
  },
  created () {
    window.addEventListener('popstate', this.popstate, false)
    if (this.$route.query.patientId) {
      // 患者
      this.getPatientInfo()
      this.getPatientDetail()
    } else {
      // 用户
      this.getUserInfo()
      this.getUserDetail()
    }
  },
  destroyed () {
    window.removeEventListener('popstate', this.popstate, false)
  },
  methods: {
    popstate () {
      console.log('发生跳转')
      if (this.$route.query.patientId) {
        this.getPatientInfo()
      } else {
        this.getUserInfo()
      }
    },
    getPatientInfo () {
      let params = {}
      if (this.$route.query.relationUserId) {
        params = {
          patientId: this.$route.query.patientId,
          relationUserId: this.$route.query.relationUserId
        }
      } else {
        params = {
          patientId: this.$route.query.patientId
        }
      }
      getPatientInfo(params).then(res => {
        if (res.data) {
          this.tableData = res.data
          this.tableData.userIcmPhone = res.data.icmPhone
          this.usersList = res.data.users
          this.userBasicInfo = []
          this.isMore = res.data.isMore
        }
      })
    },
    getUserInfo () {
      getUserInfo({
        userId: this.$route.query.userId,
        bizType: this.$route.query.bizType
      }).then(res => {
        if (res) {
          this.tableData = res.data
          this.tableData.userIcmPhone = res.data.icmPhone
          this.patientsList = res.data.patients
          this.isMore = res.data.isMore
        }
      })
    },
    getUserDetail () {
      getUserDetail({
        userId: this.$route.query.userId,
        bizType: this.$route.query.bizType
      }).then(res => {
        if (res) {
          console.log('用户更多列表：', res.data)
          this.userBasicInfo = res.data
        }
      })
    },
    getPatientDetail () {
      getPatientDetail({
        patientId: this.$route.query.patientId
      }).then(res => {
        if (res) {
          console.log('患者更多列表：', res.data)
          this.userBasicInfo = res.data
        }
      })
    },
    onClickPatient (row) {
      this.$router.push({
        name: 'patientDetails',
        query: {
          patientId: row.id
        }
      })
      getPatientInfo({
        patientId: this.$route.query.patientId
      }).then(res => {
        if (res.code === 200) {
          this.tableData = res.data
          this.tableData.userIcmPhone = res.data.icmPhone
          this.usersList = res.data.users
          this.isMore = res.data.isMore
        } else {
          this.$message.error(res.message)
        }
      })
    },
    onClickPatient1 (row) {
      this.$router.push({
        name: 'userDetails',
        query: {
          userId: row.id,
          bizType: row.bizType
        }
      })
      getUserInfo({
        userId: this.$route.query.userId,
        bizType: this.$route.query.bizType
      }).then(res => {
        if (res) {
          this.tableData = res.data
          this.tableData.userIcmPhone = res.data.icmPhone
          this.patientsList = res.data.patients
          this.userBasicInfo = []
          this.isMore = res.data.isMore
        }
      })
    },
    onEditUserInfo () {
      this.isShowEdit = true
    },
    onAll () {
      this.isShowAllInfo = true
      if (this.$route.query.patientId) {
        this.getPatientDetail()
      } else {
        this.getUserDetail()
      }
    },
    onPlaintext (e) {
      if (this.$route.query.patientId) {
        getPatientSensitiveInfo({
          authCode: '',
          id: this.tableData.id,
          dataName: e
        }).then(res => {
          if (res.code === 2003) {
            console.log('patient-result:', res.data)
            this.$confirm('此功能打开后，患者相关的敏感信息会明文显示，请注意数据安全，确定要开启此功能吗？', '系统提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.isShowIDAuth = true
              this.dataName = e
            }).catch(() => {
              //
            })
          } else {
            if (res.data.name === 'userFollowUpPhone') {
              this.tableData.followUpPhone = res.data.value
            } else if (res.data.name === 'userHfmPhone') {
              this.tableData.hfmPhone = res.data.value
            } else if (res.data.name === 'patientIdNumber') {
              this.tableData.idNumber = res.data.value
            } else if (res.data.name === 'userIcmPhone') {
              this.tableData.userIcmPhone = res.data.value
              this.$forceUpdate()
            } else if (res.data.name === 'patientIcmPhone') {
              this.tableData.icmReservedPhone = res.data.value
            }
          }
        }).catch(() => {
          this.$message.error('网络错误')
        })
      } else {
        getUserSensitiveInfo({
          authCode: '',
          id: this.tableData.id,
          dataName: e
        }).then(res => {
          if (res.code === 2003) {
            console.log('user-result:', res.data)
            this.$confirm('此功能打开后，患者相关的敏感信息会明文显示，请注意数据安全，确定要开启此功能吗？', '系统提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.dataName = e
              this.isShowIDAuth = true
            }).catch(() => {
              //
            })
          } else {
            if (res.data.name === 'userFollowUpPhone') {
              this.tableData.followUpPhone = res.data.value
            } else if (res.data.name === 'userHfmPhone') {
              this.tableData.hfmPhone = res.data.value
            } else if (res.data.name === 'patientIdNumber') {
              this.tableData.idNumber = res.data.value
            } else if (res.data.name === 'userIcmPhone') {
              this.tableData.userIcmPhone = res.data.value
              this.$forceUpdate()
            } else if (res.data.name === 'patientIcmPhone') {
              this.tableData.icmReservedPhone = res.data.value
            }
          }
        }).catch(() => {
          this.$message.error('网络错误')
        })
      }
    },
    sensitiveData (e) {
      if (e.name === 'userFollowUpPhone') {
        this.tableData.followUpPhone = e.value
      } else if (e.name === 'userHfmPhone') {
        this.tableData.hfmPhone = e.value
      } else if (e.name === 'patientIdNumber') {
        this.tableData.idNumber = e.value
      } else if (e.name === 'userIcmPhone') {
        this.tableData.userIcmPhone = e.value
      } else if (e.name === 'patientIcmPhone') {
        this.tableData.icmReservedPhone = e.value
      }
    },
    editPatientInfo () {
      this.getPatientInfo()
    },
    headerRowStyle ({ row, rowIndex }) {
      return { height: '39px' } // 设置表头行高
    }
  }
}
</script>
<style scoped lang="scss">
.userDetails-box {
  .box-title {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    padding-left: 24px;
    margin-bottom: 5px;
    gap: 5px;
    font-weight: 400;
    font-size: 14px;
    .title-one {
      color: #909399;
    }
    .title-two {
      color: #131414;
    }
  }
  .box-userBasicInfo,.box-patientInfo,.box-familyInfo {
    margin-bottom: 24px;
    margin-left: 24px;
    margin-right: 24px;
    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #0F1114;
      margin-bottom: 12px;
    }
  }
  .box-userBasicInfo {
    width: 900px;
  }
  //.box-patientInfo {
  //  ::v-deep .el-table .el-table__body-wrapper .el-table__body .el-table__row {
  //    height: 20px !important;
  //  }
  //}
}

.edit-btn {
  margin-left: 10px;
}

.el-icon-view {
  padding: 0px 5px;
  height: 21px;
  line-height: 21px;
}

</style>
