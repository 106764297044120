<template>
  <el-dialog
    title="请确认回执单信息"
    width="750px"
    height="410px"
    :visible="visible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :lock-scroll="true"
    @close="cancel"
  >
    <div class="content">
      <el-scrollbar ref="wrapper">
        <el-descriptions v-if="receiptInfo" :column="2" >
            <el-descriptions-item label="患者姓名">{{receiptInfo.patientName}}</el-descriptions-item>
            <el-descriptions-item label="植入时间">
              {{moment.unix(receiptInfo.implantDate / 1000).format('Y-MM-DD')}}
            </el-descriptions-item>
            <el-descriptions-item label="品牌型号">{{receiptInfo.modelName}}</el-descriptions-item>
            <el-descriptions-item label="设备序列号">{{receiptInfo.deviceSn}}</el-descriptions-item>
            <el-descriptions-item label="身份证号">{{receiptInfo.patientIdNumber}}</el-descriptions-item>
            <el-descriptions-item label="联系手机号">{{receiptInfo.patientPhoneView}}</el-descriptions-item>
            <el-descriptions-item label="医院术者">{{receiptInfo.operator}}</el-descriptions-item>
            <el-descriptions-item label="患者病因">{{receiptInfo.etiology}}</el-descriptions-item>
            <el-descriptions-item label="患者住址">{{receiptInfo.addressDetail}}</el-descriptions-item>
          </el-descriptions>
          <el-image
            style="width: 172px; height: 107px"
            :src="receiptInfo.imagePathView"
            :preview-src-list="[receiptInfo.imagePathView]">
          </el-image>
      </el-scrollbar>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel" size="small">返回修改</el-button>
      <el-button type="primary" @click="confirm" size="small">确定提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { createDeviceReceipt, editDeviceReceipt } from '@/serve/module/receipt'
const moment = require('moment')
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    receiptInfo: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      moment: moment
    }
  },
  watch: {
    visible: {
      handler (newVal) {
        console.log('visible:', newVal, this.receiptInfo)
        this.receiptInfo.patientPhoneView = this.receiptInfo.patientPhone
      }
    }
  },
  methods: {
    confirm () {
      this.$emit('update:visible', false)
      this.$confirm(`设备序列号提交后不可修改，请再次确认序列号${this.receiptInfo.deviceSn},确认无误后再次点击提交。`, '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.receiptInfo.isEdit) {
          if (this.receiptInfo.patientPhone.indexOf('*') !== -1) {
            this.$delete(this.receiptInfo, 'patientPhone')
          }
          this.editDeviceReceipt(this.receiptInfo)
        } else {
          this.createDeviceReceipt(this.receiptInfo)
        }
      }).catch(() => {

      })
    },
    cancel () {
      this.$emit('update:visible', false)
    },
    createDeviceReceipt (params) {
      createDeviceReceipt(params).then(res => {
        if (res.code === 200) {
          this.$message.success('提交成功')
          this.$emit('enterReceiptSuccess')
        } else {
          this.$message.error(res.message)
        }
      }).catch((e) => {
        this.$message.error(e)
      })
    },
    editDeviceReceipt (params) {
      editDeviceReceipt(params).then(res => {
        if (res.code === 200) {
          this.$message.success('修改成功')
          this.$emit('editReceiptSuccess')
        } else {
          this.$message.error(res.message)
        }
      }).catch((e) => {
        this.$message.error(e)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.content {
    // height: 400px;
}

</style>
