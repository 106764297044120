import { getOssStsToken } from '@/serve/module/common'
import store from '@/store'

const OSS = require('ali-oss')
export const createClient = function (options) {
  let opts
  if (options && options.bucket) {
    opts = options
  } else {
    if (process.env.NODE_ENV === 'production') {
      opts = {
        bucket: 'sm-cloud'
      }
    } else {
      opts = {
        bucket: 'sm-cloud-test'
      }
    }
  }

  getOssStsToken(opts).then(data => {
    const { accessKeyId, accessKeySecret, expiration, securityToken } = data.data
    store.dispatch('Oss/saveTokenExpire', new Date(expiration).getTime())
    const client = new OSS({
      region: 'oss-cn-beijing',
      accessKeyId,
      accessKeySecret,
      stsToken: securityToken,
      bucket: opts.bucket
    })
    console.log('client', client)
    store.dispatch('Oss/saveOSS', client)
  })
}

export const ossInit = function (options) {
  const expire = store.getters['Oss/getTokenExpire']
  if (!expire || expire <= new Date().getTime()) {
    createClient(options)
  }
}
