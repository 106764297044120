<template>
  <table class="icm-table" >
    <Loading v-if="isLoading" />
    <tbody v-else-if="Object.keys(icmDevice).length !== 0">
    <tr>
      <th>设备类型</th>
      <td>ICM</td>
    </tr>
    <tr>
      <th>设备序列号</th>
      <td>{{ icmDevice.deviceSn }}</td>
    </tr>
    <tr>
      <th>蓝牙mac地址</th>
      <td>{{ icmDevice.deviceAddr }}</td>
    </tr>
    <tr>
      <th>植入日期</th>
      <td>{{ formatDateToDay(icmDevice.implantDate) }}</td>
    </tr>
    <tr>
      <th>植入医院</th>
      <td>{{ icmDevice.hospitalName }}</td>
    </tr>
    <tr>
      <th>医院术者</th>
      <td>{{ icmDevice.operator }}</td>
    </tr>
    <tr>
      <th>患者病因</th>
      <td>{{ icmDevice.etiology }}</td>
    </tr>
    <tr>
      <th>自动上传周期</th>
      <td>{{ autoUploadPeriodText }}</td>
    </tr>
    <tr>
      <th>自动上传时间点</th>
      <td>{{ icmDevice.autoUploadTime > -1 ? formatTimeToAmPm(icmDevice.autoUploadTime) : '' }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import { formatDate, formatDateToDay, formatTimeToAmPm } from '@/utils/dateUtils'
import Loading from '@/components/Loading.vue' // 导入 Loading 组件

export default {
  name: 'ICMTable',
  components: {
    Loading // 注册 Loading 组件
  },
  props: {
    icmDevice: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatDate,
    formatDateToDay,
    formatTimeToAmPm
  },
  computed: {
    autoUploadPeriodText () {
      switch (this.icmDevice.autoUploadPeriod) {
        case 0:
          return '关闭'
        case 1:
          return '按天'
        case 2:
          return '按周'
        case 3:
          return '按月'
        default:
          return ''
      }
    }
  }
}
</script>

<style scoped>
.icm-table {
  border-collapse: collapse; /* 合并边框 */
  width: 100%;
  border: 1px solid #ddd; /* 添加外边框 */
}

.icm-table th,
.icm-table td {
  padding: 8px; /* 添加内边距 */
  border: none; /* 去掉单元格边框 */
  height: 38px; /* 设置单元格高度 */
}

.icm-table th {
  background-color: #f5f6f7; /* 表头背景色 */
  text-align: left; /* 表头文字左对齐 */
  font-size: 14px; /* 表头文字字号 */
  font-weight: 420; /* 表头文字字重 */
  line-height: 22px; /* 表头文字行高 */
  color: #51565D; /* 表头文字颜色 */
  width: 160px; /* 设置表头宽度 */
}
</style>
