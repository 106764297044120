import { get, post } from '@/serve/axios'

// 用户管理
// 1. 用户列表
export const getUserList = (params) => get('admin/v1/user/list', { params })

// 2. 获取患者信息
export const getPatientInfo = (params) => get('admin/v1/patient/detail', { params })

// 3. 获取用户信息
export const getUserInfo = (params) => get('admin/v1/user/detail', { params })

// 4. 扫码获取用户敏感数据
export const getUserSensitiveInfo = (params) => get('admin/v1/user/sensitive', { params })

// 5. 扫码获取患者敏感数据
export const getPatientSensitiveInfo = (params) => get('admin/v1/patient/sensitive', { params })

// 6. 编辑患者信息
export const editPatientInfo = (params) => post('admin/v1/patient/edit', params)

// 7. 获取用户更多列表
export const getUserDetail = (params) => get('admin/v1/user/detail/list', { params })

// 8. 获取患者更多列表
export const getPatientDetail = (params) => get('admin/v1/patient/detail/list', { params })

// 9. 查询患者ICM设备信息
export const getPatientIcmDetail = (params) => get('admin/v1/patient/patient-icm-detail', { params })

// 10. 查询患者网关信息
export const getPatientGatewayDetail = (params) => get('admin/v1/patient/patient-gateway-detail', { params })
